//logo
import caraLogo from '../assets/images/project/CARA/CARA-logo.png'
import singtelLogo from '../assets/images/project/Singtel/singtel-logo.png'
import singtelLogoLight from '../assets/images/project/Singtel/logo-light.png'
import singtelLogoDark from '../assets/images/project/Singtel/logo-dark.png'
import SingtelIoTLogo from '../assets/images/project/SingtelIoT360/logo.png'
import SingtelIoTLogoDark from '../assets/images/project/SingtelIoT360/logo-dark.png'
import SingtelIoTLogoLight from '../assets/images/project/SingtelIoT360/logo-light.png'
import PinknodeLogo from '../assets/images/project/Pinknode/logo.png'
// import AutomateLogo from '../assets/images/project/Automa8e/logo.png'
import AffluentLogo from '../assets/images/project/Affluent/logo.png'

//Main Background
import caraMainBg from '../assets/images/project/CARA/cara-bg-main.png'
import singtelMainBg from '../assets/images/project/Singtel/singtel-bg-main.png'
import SingtelIoTMainBg from '../assets/images/project/SingtelIoT360/main-bg.png'
import PinknodemMainBg from '../assets/images/project/Pinknode/bg-main.png'
// import AutomateMainBg from '../assets/images/project/Automa8e/bg-main.png'
import AffluentMainBg from '../assets/images/project/Affluent/bg-main.png'

//Welcome Image
import caraWelcomeImg from '../assets/images/project/CARA/cara-welcome-image.png'
import singtelWelcomeImg from '../assets/images/project/Singtel/welcome-image.png'
import singtelIoTWelcomeImg from '../assets/images/project/SingtelIoT360/welcome-image.png'
import pinknodeWelcomeImg from '../assets/images/project/Pinknode/welcome-image.png'
// import automateWelcomeImg from '../assets/images/project/Automa8e/welcome-image.png'
import affluentWelcomeImg from '../assets/images/project/Affluent/welcome-image.png'

//About Project
import caraAboutImg from '../assets/images/project/CARA/about-project-image.png'
import singtelAboutImg from '../assets/images/project/Singtel/about-image.png'
import singtelIoTAboutImg from '../assets/images/project/SingtelIoT360/about-image.png'
import pinknodeAboutImg from '../assets/images/project/Pinknode/about-image.png'
// import automateAboutImg from '../assets/images/project/Automa8e/about-image.png'
import affluentAboutImg from '../assets/images/project/Affluent/about-image.png'

//Galery
import caraGalery1 from '../assets/images/project/CARA/cara-galery-1.png'
import caraGalery2 from '../assets/images/project/CARA/cara-galery-2.png'
import caraGalery3 from '../assets/images/project/CARA/cara-galery-3.png'
import pinknodeGalery1 from '../assets/images/project/Pinknode/galery-1.png'
// import automateGalery1 from '../assets/images/project/Automa8e/galery-1.png'
import affluentGalery1 from '../assets/images/project/Affluent/galery-1.png'
import singtelGalery1 from '../assets/images/project/Singtel/galery-1.png'

//Achievement
import caraAchievement from '../assets/images/project/CARA/achievement-background.png'


export const projects = [
    {
        id:1,
        name:"CARA",
        summary:["Digitizing an offline government program for person\'s living with dementia. The application was born to take over the facilitation of the National Council of Social Services (NCSS) safe return program and take it to a digital platform."],
        mainBg: caraMainBg,
        logo: caraLogo,
        logoLight:caraLogo,
        logoDark:caraLogo,
        color:'#502773',
        secondaryColor:"#F2C94C",
        welcome: {
            image:caraWelcomeImg,
            description:'A pioneering membership programme by Dementia Singapore (formerly known as Alzheimer\'s Disease Association).',
            weDelivered: "Idea Consulting, UI/UX Design, Mobile Application Development, Web Development",
            year: 2021,
            client:"Alzheimer\'s Disease Association"
        },
        about:{
            description: "Digitizing an offline government program for person\'s living with dementia. The application was born to take over the facilitation of the National Council of Social Services (NCSS) safe return program and take it to a digital platform",
            goals:"Connecting person’s living with dementia to caregivers and government authorities to get them home safely.",
            image: caraAboutImg,
        },
        galery:[caraGalery1, caraGalery2, caraGalery3],
        achievement:{
            title1:'Person Living With Dementia',
            value1:"1000+",
            title2:"Caregivers",
            value2:'2000+',
            link:['https://play.google.com/store/apps/details?id=com.embreo.carasg', 'https://apps.apple.com/sg/app/cara-sg/id1553855834'],
            image: caraAchievement
        }
    },
    {
        id:2,
        name:"Singtel",
        summary: ["Singtel wanted to create new points of distribution for their IOT solutions to new businesses and revise the customer acquisition journey of how IOT solutions are being consumed today.", " The application was born as a marketplace that allows B2B users to purchase and implement their IOT applications."],
        mainBg: singtelMainBg,
        logo: singtelLogoLight,
        logoLight:singtelLogoLight,
        logoDark:singtelLogoDark,
        welcome: {
            image:singtelWelcomeImg,
            description:'An initiative that productizes and digitizes distribution of IOT services.',
            weDelivered: "Graphic Design, UI/UX Design, Web Development",
            year: null,
            client:"Singtel"
        },
        about:{
            description:  ["Singtel wanted to create new points of distribution for their IOT solutions to new businesses and revise the customer acquisition journey of how IOT solutions are being consumed today.", " The application was born as a marketplace that allows B2B users to purchase and implement their IOT applications."],
            goals:"Increase sales of IOT APIs/solutions by developing a seamless market place for accessing all IOT offerings.",
            image: singtelAboutImg,
        },
        galery:[singtelGalery1],
        achievement: null,
    },
    {
        id: 3,
        name: "Singtel IoT 360",
        logo: SingtelIoTLogoLight,
        logoLight:SingtelIoTLogoLight,
        logoDark:SingtelIoTLogoDark,
        mainBg:SingtelIoTMainBg,
        summary:["Enabling our customers to have access to smart insights that drives business outcomes. That was the purpose of IoT 360. By developing a data analytics dashboard, integrated with a voice-driven AI, IoT 360 sets out to provide business owners employing IoT solutions with insights and outcomes that delivers clear actionables, as well as business impact."],
        welcome: {
            image:singtelIoTWelcomeImg,
            description:'An initiative that productizes and digitizes distribution of IOT services.',
            weDelivered: "Idea Consulting, UI/UX Design,Mobile Application Development,Web Development",
            year: null,
            client:"Singtel IoT 360"
        },
        about:{
            description: ["Enabling our customers to have access to smart insights that drives business outcomes. That was the purpose of IoT 360. By developing a data analytics dashboard, integrated with a voice-driven AI, IoT 360 sets out to provide business owners employing IoT solutions with insights and outcomes that delivers clear actionables, as well as business impact."],
            goals:"A proof of concept product that was used to demo to potential customers during the MWC conference.",
            image: singtelIoTAboutImg,
        },
        galery:[],
        achievement: null,
    },
    {
        id: 4,
        name: 'Pinknode',
        logo:PinknodeLogo,
        logoLight:PinknodeLogo,
        logoDark:PinknodeLogo,
        mainBg:PinknodemMainBg,
        summary:["Pinknode is a infrastructure-as-a-service (IAAS) company focused on supplying blockchain developers with easily scalable Polkadot blockchain networks, all maintenance free."],
        welcome: {
            image:pinknodeWelcomeImg,
            description:'An infrastructure as a service company powering the future development of blockchain via the Polkadot network.',
            weDelivered: "Infrastructure Dashboard, Integration to APIs",
            year: null,
            client:"Pinknode"
        },
        about:{
            description: ["Pinknode is a infrastructure-as-a-service (IAAS) company focused on supplying blockchain developers with easily scalable Polkadot blockchain networks, all maintenance free."],
            goals:"Allow users to easily access and manage their own Polkadot nodes thrugh a infrastructure dashboard which displays health of all nodes, network requests and loads for users to manage their own projects.",
            image: pinknodeAboutImg,
        },
        galery:[pinknodeGalery1],
        achievement: null,
    },
    // {
    //     id: 5,
    //     name: 'Automa8e',
    //     logo:AutomateLogo,
    //     logoLight:AutomateLogo,
    //     logoDark:AutomateLogo,
    //     mainBg: AutomateMainBg,
    //     summary:["Automa8e is a enterprise resource planning (ERP) startup with a focus on simplifying business transactions."],
    //     welcome: {
    //         image:automateWelcomeImg,
    //         description:'A business transactions startup, focused on simplifying business processes for customers.',
    //         weDelivered: "Frontend Development, Integration to APIs",
    //         year: null,
    //         client:"Automa8e"
    //     },
    //     about:{
    //         description: ["Automa8e is a enterprise resource planning (ERP) startup with a focus on simplifying business transactions."],
    //         goals:"To simplify the endpoints of all business transactions to seamlessly flow into a centralized ERP system that can generate business insights and fulfil business operations.",
    //         image: automateAboutImg,
    //     },
    //     galery:[automateGalery1],
    //     achievement: null,
    // },
    {
        id: 6,
        name: 'Affluent',
        logo:AffluentLogo,
        logoLight:AffluentLogo,
        logoDark:AffluentLogo,
        mainBg: AffluentMainBg,
        summary:["Affluent is an estate planning consultancy, focused on legacy planning for customers to be smooth and conflict-free. "],
        welcome: {
            image:affluentWelcomeImg,
            description:'Helping people conduct estate planning in an affordable manner.',
            weDelivered: "Fullstack Development, Web Application",
            year: null,
            client:"Affluent"
        },
        about:{
            description: ["Affluent is an estate planning consultancy, focused on legacy planning for customers to be smooth and conflict-free. "],
            goals:"To develop a self-serve will writing application, that allows customers to create their own wills at the touch of a button without the hefty legal fees that comes with a standard will writing service.",
            image: affluentAboutImg,
        },
        galery:[affluentGalery1],
        achievement: null,
    },
]