import React from 'react'
import Modal from 'react-modal'

const CModal = ({openModal, onClickClose, children, overlayBackgorund}) => {
    return (
        <div>
            <Modal
                isOpen={openModal}
                onRequestClose={onClickClose}
                style={{
                    overlay:{
                        zIndex:'999',
                        background: overlayBackgorund? overlayBackgorund: "rgba(7, 50, 90, 0.35)",
                    },
                    content: {
                        zIndex:'999',
                        border:'none',
                        background:'none',
                        marginRight: '-50%',
                        top:'50%',
                        bottom:'auto',
                        left:'50%',
                        right:'auto',
                        transform: 'translate(-50%, -50%)',
                    }
                }}
                >
                    {children}
            </Modal>
        </div>
    )
}

export default CModal
