import React, {useState} from 'react'
import './style.css'

export default function ScrollAnimation({onClick}) {

  let scrollY = 0;



  if (window) {
    scrollY = window.scrollY
  }

  return (
    <div className='fixed scroll-animation-wrapper' style={{opacity: ((scrollY-400)*-1)/400}}>
      <div className='scrollIconContainer'>
          <div className='scrollWidget' onClick={onClick} style={{cursor:'pointer'}} >
            <div className='scrollWidgetBall'>

            </div>
        </div>
        <span className='text-base' onClick={onClick} style={{cursor:'pointer'}} >Scroll to read</span>
      </div>
    </div>
  )
}
