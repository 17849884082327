import React, {useState, useEffect} from 'react'
import './style.css'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'
import {BiDownArrow} from 'react-icons/bi'

//Components
import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation'
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop'
import Chatbox from '../../components/Chatbox/Chatbox'


//Images
import business1Image from '../../../assets/images/ilustration/Partnership/business-image-1.png'
import business2Image from '../../../assets/images/ilustration/Partnership/business-image-2.png'
import business3Image from '../../../assets/images/ilustration/Partnership/business-image-3.png'

import client1Image from '../../../assets/images/ilustration/Partnership/client-image-1.png'
import client2Image from '../../../assets/images/ilustration/Partnership/client-image-2.png'
import client3Image from '../../../assets/images/ilustration/Partnership/client-image-3.png'

import referal1Image from '../../../assets/images/ilustration/Partnership/referal-image-1.png'
import referal2Image from '../../../assets/images/ilustration/Partnership/referal-image-2.png'
import referal3Image from '../../../assets/images/ilustration/Partnership/referal-image-3.png'

import arrowDown from '../../../assets/images/arrow-down.png'

//Components
import Progress from '../../components/RobotProgress'
import StarBackground from '../../components/StarBackground/StarBackground'

//Data
import robotData from './robotData'

export default function Robot() {

    const history = useHistory()

    const {partnershipPage} = useSelector(state=> state.theme.component)
    const {theme} = useSelector(state=> state.theme)

    const [progressValue, setProgressValue] = useState(1)
    const [robotDataDetails, setRobotDataDetails] = useState(null)
    useEffect(()=>{
        setRobotDataDetails(robotData[progressValue-1])
    },[progressValue])

    const [showDesc, setShowDesc] = useState(true)

    const handleShowDescription = () =>{
        setShowDesc(!showDesc)
    }

    const [animationHelper, setAnimationHelper] = useState(true)
    useEffect(()=>{
        setAnimationHelper(false)
        setTimeout(()=>{
            setAnimationHelper(true)
        }, 500)
    },[progressValue])

  return (
    <div className=' min-h-screen robot-head relative' onWheel={(event)=> null} >
        {/* <StarBackground/> */}
        {/* {robotDataDetails && animationHelper &&
            <div className='flex w-full top-36'>
                 {
                    !showDesc &&
                    <div className='flex w-full'>
                        <div className="green-rect"></div>
                        <div className="green-rect-reverse"></div>
                    </div>
                } 
                <div className='w-full flex justify-center ' >
                    <div className={ progressValue === 4 ? 'w-102':progressValue > 4? 'w-72': 'w-48'}  >
                        <img src={robotDataDetails.robotComp}   />
                    </div>
                    <img src={robotDataDetails.robotComp} style={{width:'100%'}}  />
                </div>
            </div>
        } */}
        
        {/* <Chatbox/> */}
        {robotDataDetails && animationHelper &&
            <div className='relative lg:absolute flex w-full top-36'>
                {
                    !showDesc &&
                    <div className='flex w-full'>
                        <div className={`green-rect ${theme === "DARK"?'bg-secondaryYellow': 'bg-accentGreen'}`}></div>
                        <div className={`green-rect-reverse ${theme === "DARK"? 'bg-secondaryYellow': 'bg-accentGreen'}`}></div>
                    </div>
                }
                <div className='relative lg:absolute w-full flex justify-center ' >
                    <div className={ progressValue === 4 ? 'w-102':progressValue > 4? 'w-72': 'w-48'}  >
                        <img src={robotDataDetails.robotComp}   />
                    </div>
                </div>
            </div>
        }
        {robotDataDetails &&
            <div className={'relative min-h-screen flex px-4 lg:px-20'} style={{minHeight:"96vh"}}>
                <div className={`flex flex-col  pb-20 w-full ${!showDesc ? "justify-between ":''}`}>
                    {
                        !showDesc &&
                        <div onClick={()=> history.goBack()} className={`mt-10 relative flex items-center ${theme === "LIGHT"?"text-primaryOrange":"text-secondaryYellow"}  mt-32 font-bold`} style={{cursor:'pointer', zIndex:999}}>
                            <HiOutlineArrowNarrowLeft className='text-base' />
                            <span className='text-base ml-2'>Back</span>
                        </div>
                    }
                    <div className={`flex w-full justify-between  ${showDesc? "mt-40 lg:mt-32":"mt-96 lg:mt-40 2xl:mt-10"}`} >
                        <div className='text-3xl md:text-5xl 2xl:text-7xl font-bold '>
                            {robotDataDetails.label} <br/> <span className='text-primaryOrange' >Phase</span>
                            {/* <span className='flex mt-10'>
                                <span className='text-accentGreen'>0{progressValue} / </span>
                                <span className='see-more flex ml-6' onClick={()=> handleShowDescription()}>
                                    <img  src={arrowDown} className={`m-auto ${showDesc? "see-more-icon-rotate-top":"see-more-icon-rotate-bottom"} `} />
                                </span>
                            </span>
                            <p className='text-xs mt-4' style={{color:'lightgray'}}>* Click to read more</p> */}
                        </div>
                        {true && <Progress sendProgressValue={(value)=> setProgressValue(value)} currentProggressValue={progressValue} />}
                    </div>
                    {
                        showDesc &&
                        <div className='pb-10' >
                            <div className='flex flex-col lg:flex-row justify-between mt-10'>
                                <div className={progressValue == 4? "w-full lg:w-1/3": progressValue > 1? "w-full lg:w-2/5": null}>
                                    {robotDataDetails.firstDescription}
                                </div>
                                <div className={progressValue == 4? "w-full lg:w-1/3 mt-10 lg:mt-0":progressValue > 1? "w-full lg:w-2/5 mt-10 lg:mt-0": null}>
                                    {robotDataDetails.secondDescription}
                                </div>
                            </div>
                            <div className='mt-32'>
                                <p className='text-accentGreen' style={{fontSize:'20px', fontWeight:700}}>Result of this Process</p>
                                <div className={`flex flex-wrap mt-4`}>
                                    {
                                        robotDataDetails.result.map((el, i)=>{
                                            return (
                                                <div className={`flex items-center pl-4 mr-4 mt-6 lg:mt-4 ${ i > 0 ?"icon-desc-border":''}`}>
                                                    <img className={theme === "DARK"? "dark-icon":""} src={el.lightIcon} />
                                                    <p className='ml-8'>{el.label}</p>
                                                </div>
                                            )
                                        }) 
                                        
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }

    </div>
  )
}
