import React, {useState, useEffect} from 'react'
import './style.css'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'
import {BiDownArrow} from 'react-icons/bi'
import {RiInformationFill} from 'react-icons/ri'

//Data
import teamData from './teamData'

export default function Team() {

    const history = useHistory()

    const {partnershipPage} = useSelector(state=> state.theme.component)
    const {theme} = useSelector(state=> state.theme)


  return (
    <div className=' min-h-screen robot-head relative' onWheel={(event)=> null} >
        
        {/* <Chatbox/> */}
        {teamData  &&
            <div className='absolute  w-full mt-72 lg:mt-56' >
                <div className='flex w-full'>
                    <div className={`green-rect ${theme === "DARK"?'bg-secondaryYellow': 'bg-accentGreen'}`}></div>
                    <div className={`green-rect-reverse ${theme === "DARK"?'bg-secondaryYellow': 'bg-accentGreen'}`}></div>
                </div>
                <div className='absolute w-full flex  justify-center ' style={{top: "-30px"}} >
                    <div className={ 'w-48'}  >
                        <img src={teamData[0].image}   />
                        <div className='text-center mt-4'>
                            <span className='font-bold text-lg' >Kit</span>
                            <br/>
                            <span className='text-sm' >Founder</span>
                        </div>
                    </div>
                </div>
            </div>
        }
        {teamData &&
            <div className={'relative min-h-screen flex pb-20 px-4  lg:px-20'}>
                <div className={`w-full`}>
                    <div className='flex  md:justify-between mt-32'>
                        <div onClick={()=> history.goBack()} className={` flex ${theme === "LIGHT"?"text-primaryOrange":"text-secondaryYellow"} items-center font-bold`} style={{cursor:'pointer'}}>
                            <HiOutlineArrowNarrowLeft className='text-base' />
                            <span className='text-base ml-2'>Back</span>
                        </div>
                        <div className='text-center lg:ml-40 greet-text'>
                            <div >
                                <span className='text-sm'>Meets</span>
                                <br/>
                                <span className='text-lg font-bold text-primaryOrange'>Embreo Team</span>
                            </div>
                        </div>
                        <div className='items-center info-note'>
                            <span className='text-sm' style={{letterSpacing: "0.015em"}}>Hover avatar to interact with </span>
                            <RiInformationFill className="ml-2 text-primaryOrange text-xl" />
                        </div>
                    </div>
                    
                    <div className='justify-center greet-text-alt'>
                        <div className='text-center'  >
                            <span className='text-base'>Meets</span>
                            <br/>
                            <span className='text-xl font-bold text-primaryOrange'>Embreo Team</span>
                        </div>
                    </div>
                    <div className='flex flex-wrap mt-72'>
                        {
                            teamData && teamData.filter((el,i)=> i > 0).map((item, index)=>{
                                return (
                                    <div key={index} className={ 'w-32 flex lg:w-1/4 items-center flex-col mt-8 ml-4 md:ml-12 lg:ml-0 '}  >
                                        <div className='w-32'>
                                            <img src={item.image}   />
                                        </div>
                                        <div className='text-center mt-4'>
                                            <span className='font-bold text-lg' >{item.name}</span>
                                            <br/>
                                            <span className='text-sm' >{item.role}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/* <div className={`flex w-full justify-between mt-32`} >
                        <div className="">
                        
                    </div> */}
                </div>
            </div>
        }

    </div>
  )
}
