import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import './index.css'
import {Color} from '../../../theme/color/index'
import {MdMail} from 'react-icons/md'
import {FaTimes} from 'react-icons/fa'

//Actions
import {toggleDarkMode} from '../../../redux/actions/theme'

//Components
import CModal from '../../components/Modals/CModal'
import CButton from '../../components/Button/CButton'
import Stars from '../../components/StarBackground/StarBackground'

export default function Welcome(props) {

  const dispatch = useDispatch()
  const history = useHistory()
  
  const {welcomePage} = useSelector(state => state.theme.component )
  const {theme} = useSelector(state => state.theme )

  const [showLetter, setShowLetter] = useState(false)
  const [showLetterNotif, setShowLetterNotif] = useState(1)

  useEffect(()=>{
    if (localStorage.getItem('showLetterNotif')) {
      const letterNotif = localStorage.getItem('showLetterNotif')
      setShowLetterNotif(Number(letterNotif))
    }
    
  },[])

  const handleShowLetter =() =>{
    // const audio = new Audio('../../../assets/images/ilustration/ring_sound.wav')
    // audio.play()
    setShowLetter(true)
    localStorage.setItem('showLetterNotif', 0)
    setShowLetterNotif(0)
  } 


  return (
    <div className='flex flex-col pt-20 md:pt-20 md:justify-between' style={{minHeight:'100vh', overflow:'hidden'}}>
      <div className='welcome-text pt-5 text-3xl md:text-5xl 2xl:text-7xl'>
        Welcome to <span style={{color: Color.primaryOrange}}>Embreo!</span>
        <span className='welcome-description text-base mt-4'>If you're here, it means most likely you have an idea at the top of your head!</span>
      </div>
      <div className='relative' >
        <Stars/>
        <div className='flex justify-around items-center mt-5 md:mt-10 px-20'>
          <div className=' w-52 h-52 hidden sm:block'>
            <img src={welcomePage.messyPaper} alt={"Messy Paper"} />
          </div>
          <div className='relative' style={{zIndex:'10'}}>
            <img src={welcomePage.kitWelcome} alt={"Kit Welcome"} />
            <div onClick={()=> handleShowLetter()} className=' message-wrapper absolute' >
              {/* <img src={welcomePage.kitMessage} alt={"Kit's message"} /> */}
              <div className={`message-container w-40 h-16  sm:w-60 sm:h-12 flex items-center pb-4 px-2 ${theme === 'LIGHT'?"bg-lightPrimaryOrange":"bg-lightAccentGreen"}`}  >
                <MdMail className={`${ theme ==="DARK"? 'text-accentGreen':"text-primaryOrange"} mr-4 text-2xl`} />
                <span className={theme === "LIGHT"? 'text-primaryOrange':'text-accentGreen'} style={{fontSize:'14px'}}>You have a new message</span>
              </div>
              {
                Boolean(showLetterNotif) &&
                <div className={`absolute ${theme === "LIGHT"? "bg-primaryOrange": "bg-accentGreen"}`} style={{width:'10px', height:'10px', borderRadius:'50%', top:'-2px', right:'-2px', zIndex:'5'}}>

                </div>
              }
            </div>
          </div>
          <div className='w-52 h-52 hidden sm:block'>
            <img src={welcomePage.messyLaptop} alt={"Messy Laptop"} />
          </div>
        </div>
        <div className='relative' >
          {/* <img src={welcomePage.rect} alt='orange rect' /> */}
          <div className='flex justify-center'>
            <div className={`rect w-96 md:w-full h-96 ${ theme === "DARK"? "bg-secondBlack":"bg-primaryOrange"}`}></div>
          </div>
          <div className='absolute' id="playAroundButton">
            <CButton type={theme === "DARK"?"primary":'secondary'} className="px-10" onClick={()=> history.push('/home')} >
              Play Around!
            </CButton>
          </div>
        </div>
      </div>
      <CModal openModal={showLetter} onClickClose={()=> setShowLetter(false)} overlayBackgorund={'rgb(0,0,0,0.2)'}>
        <div className={`${theme === "LIGHT"?"bg-primaryOrange":"bg-accentGreen"} p-10 pt-4 rounded letter-popup`} style={{color:'white'}} >
          <div className='w-full flex justify-end'>
            <FaTimes style={{color:'white', fontSize:'18px', cursor:'pointer'}} onClick={()=> setShowLetter(false)} />
          </div>
          <br />
          <b>At Embreo, your success is our success.</b> 
          <br /><br />
          We aren't mercenaries who aim to churn projects. Rather, we incubate your ideas and product models with you to find a proper product-market fit.
          <br /><br />
          Projects that come through our door ultimately carries on to take lives of their own and we want them to succeed. 
          <br /><br />
          After all, we are their co-parents and nobody wants their child to fail. 
          <br /><br />
          Feel free to browse around and take that leap of faith with us!
          <br /><br />
          Yours Sincerely,
          <br/>
          Kit Leong (Founder)
        </div>
      </CModal>
    </div>
  )
}
