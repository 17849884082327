import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import {HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight} from 'react-icons/hi'
import './style.css'

//Components
import CButton from '../../components/Button/CButton'
import StarBackground from '../../components/StarBackground/StarBackground'

//image
import EmbroOffice from '../../../assets/images/EmbreoOfficeNew.png'


export default function Office() {

    const history = useHistory()

    const {partnershipPage} = useSelector(state=> state.theme.component)
    const {theme} = useSelector(state=> state.theme)


  return (
      <div className=' relative custom-office-scrollbar' style={{minHeight: "75vh", paddingBottom:'200px', overflow:'auto' }} >
        {/* <StarBackground zIndex={'99'}/> */}
        <div className='flex justify-center' style={{minWidth:'1349px'}}>
            <div className='px-20 relative flex justify-center' style={{width:'1349px', height:'625px',}}   >
                <div >
                    <img  style={{ top:'-50px', left:'0', position:'relative', zIndex:"2", backgroundColor:theme === "DARK"? "#1C1D24": null}} src={EmbroOffice}/>
                </div>
                {/* {theme === 'LIGHT' &&
                    <div className='w-full flex justify-center absolute' style={{bottom:'-110px', left:"-10px", zIndex:'1'}}>
                        <div style={{width:"1000px", height:'100px', backgroundColor:'#F2F2F2', borderRadius:'50%'}} ></div>
                    </div>
                } */}
                    <div className='h-32 w-full pl-56 flex items-end absolute' style={{zIndex:999}}>
                        <div onClick={()=> history.push('/home')} className={`mt-0 flex ${theme === "LIGHT"?"text-primaryOrange":"text-secondaryYellow"}  items-center font-bold`} style={{cursor:'pointer'}}>
                            <HiOutlineArrowNarrowLeft className='text-base' />
                            <span className='text-base ml-2'>Exit</span>
                        </div>
                    </div>
                    <div className='text-center font-bold absolute w-full pl-40' style={{top:"100px", left:'-50px', zIndex:'3'}}>
                        <span className='text-4xl'>Welcome to</span>
                        <br/>
                        <span className='text-6xl text-primaryOrange'>Embreo Office</span>
                    </div>
                    <div className='office-ourTeam'>
                        <Link to={"/team"} >
                            <CButton type={'primary'} className="px-6 py-2 yellow-shadow" style={{width:'fit-content', boxShadow: "8px 8px #FFCD05"}}>
                                <div className='flex items-center'>
                                    Our Team
                                    <HiOutlineArrowNarrowRight className='ml-2 text-2xl text-secondaryYellow' />
                                </div>
                            </CButton>
                        </Link>
                    </div>
                    <div className='office-partnership'>
                        <Link to={"/partnership"} >
                            <CButton type={'primary'} className="px-6 py-2 mb-20 yellow-shadow" style={{width:'fit-content', boxShadow: "8px 8px #FFCD05"}}>
                                <div className='flex items-center'>
                                    Partnership Models
                                    <HiOutlineArrowNarrowRight className='ml-2 text-2xl text-secondaryYellow' />
                                </div>
                            </CButton>
                        </Link>
                    </div>
                    <div className='office-ourWorks'>
                        <Link to={"/works"} >
                            <CButton type={'primary'} className="px-6 py-2 mb-20 yellow-shadow" style={{width:'fit-content', boxShadow: "8px 8px #FFCD05"}}>
                                <div className='flex items-center'>
                                    Our Works
                                    <HiOutlineArrowNarrowRight className='ml-2 text-2xl text-secondaryYellow' />
                                </div>
                            </CButton>
                        </Link>
                    </div>
                    <div className='office-ourService'>
                        <Link to={"/offers"} >
                            <CButton type={'primary'} className="px-6 py-2 mb-20 yellow-shadow" style={{width:'fit-content', boxShadow: "8px 8px #FFCD05"}}>
                                <div className='flex items-center'>
                                    Our Service
                                    <HiOutlineArrowNarrowRight className='ml-2 text-2xl text-secondaryYellow' />
                                </div>
                            </CButton>
                        </Link>
                    </div>
                    <div className='office-robot'>
                        <Link to={"/robot"} >
                            <CButton type={'primary'} className="px-6 py-2 mb-20 yellow-shadow" style={{width:'fit-content', boxShadow: "8px 8px #FFCD05"}}>
                                <div className='flex items-center'>
                                    R.O.B.O.T
                                    <HiOutlineArrowNarrowRight className='ml-2 text-2xl text-secondaryYellow' />
                                </div>
                            </CButton>
                        </Link>
                    </div>
                    <div className='office-workWithUs'>
                        <Link to={"/contact"} >
                            <CButton type={'primary'} className="px-6 py-2 mb-20 yellow-shadow" style={{width:'fit-content', boxShadow: "8px 8px #FFCD05"}}>
                                <div className='flex items-center'>
                                    Work With Us
                                    <HiOutlineArrowNarrowRight className='ml-2 text-2xl text-secondaryYellow' />
                                </div>
                            </CButton>
                        </Link>
                    </div >
            </div>
        </div>
      </div>
  )
}
