import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams, Link } from 'react-router-dom'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'
import './style.css'

//Data
import {projects} from '../../../data/works'

//Components
import CButton from '../../components/Button/CButton'
import Stars from '../../components/StarBackground/StarBackground'

//Image
import cloud from '../../../assets/images/cloud-Light.png'
import cloudDark from '../../../assets/images/cloud-Light.png'
import playstore from '../../../assets/images/Playstore Button.png'
import appstore from '../../../assets/images/Appstore Button.png'

export default function DetailProject() {

    const history = useHistory()
    const params = useParams()

    const {work} = useSelector(state=> state.theme.component)
    const {theme} = useSelector(state=> state.theme)

    const [singleProject, setSingleProject] = useState(null)

    useEffect(()=>{
        if ( projects && projects.length > 0 && params.id) {
            setSingleProject(projects.filter(el=> el.name.toLowerCase() === params.id.toLowerCase())[0])
        }
    },[])

  return (
    <div className='relative min-h-screen flex flex-col justify-center' 
        
        > {
            singleProject && singleProject.welcome &&
            <div className=' md:mt-10 lg:mt-20 px-4 lg:px-20  relative'>
                {/* <Stars zIndex={'2'} hideStar={['star1']} /> */}
                <div onClick={()=> history.goBack()} className={` mt-20 ${theme === "LIGHT"?"text-primaryOrange":"text-secondaryYellow"}  items-center font-bold relative detail-project-back-button`} style={{cursor:'pointer', zIndex:99}}>
                    <HiOutlineArrowNarrowLeft className='text-base' />
                    <span className='text-base ml-2'>Back</span>
                </div>
                <div className='mt-10 flex flex-col-reverse lg:flex-row justify-between relative' style={{zIndex:3}}>

                    <div className='mt-10 lg:mt-0 lg:w-1/2'>
                        <div className='text-3xl md:text-4xl xl:text-5xl 2xl:text-7xl font-bold flex items-center lg:items-end'>
                            <span className={singleProject.name === "Singtel" &&'mb-3'}>Hi, this is</span>
                            <div className='ml-2 w-1/2 md:w-auto lg:w-1/2 lg:ml-4 relative' style={{zIndex:99}}>
                                <div className={`w-full md:w-2/3 lg:w-full lg:w-full ${singleProject.id === 3? 'logo-singtel-iot':''}`}>
                                    <img src={theme === "DARK"? singleProject.logoDark:singleProject.logoLight} />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className='text-xl font-semibold'>
                            {singleProject.welcome.description}
                        </div>
                        <br/>
                        <br/>
                        <div className='flex'>
                            <div className='w-3/5 pr-4'>
                                <span className='text-base font-bold' >We Delivered</span>
                                <br/>
                                <span>{singleProject.welcome.weDelivered}</span>
                            </div>
                            {
                                singleProject.welcome.year &&
                                <div className='w-1/5 px-4'>
                                    <span className='text-base font-bold' >Year</span>
                                    <br/>
                                    <span>{singleProject.welcome.year}</span>
                                </div>
                            }
                            <div className='w-2/5 px-4'>
                                <span className='text-base font-bold' >Client</span>
                                <br/>
                                <span>{singleProject.welcome.client}</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-start lg:justify-end'>
                        <div className='w-3/5 2xl:w-full'>
                            <img src={singleProject.welcome.image} />
                        </div>
                    </div>
                </div>
                <div onClick={()=> history.goBack()} className={` mt-20 ${theme === "LIGHT"?"text-primaryOrange":"text-secondaryYellow"}  items-center font-bold relative detail-project-back-button-large`} style={{cursor:'pointer', zIndex:99}}>
                    <HiOutlineArrowNarrowLeft className='text-base' />
                    <span className='text-base ml-2'>Back</span>
                </div>
            </div>
        }
        {
            singleProject && singleProject.about &&
            <div className='mt-20 flex flex-col-reverse lg:flex-row justify-between px-4 lg:px-20'>
                <div className='mt-10 lg:mt-0 lg:w-1/2'>
                    <div className='text-3xl md:text-5xl 2xl:text-7xl font-bold text-primaryOrange flex'>
                        <span>About this project{singleProject.name === "CARA"? "s":''}</span>
                    </div>
                    <br />
                    <div className='text-base'>
                        {singleProject.about.description}
                    </div>
                    <br/>
                    <br/>
                    <div className='text-3xl md:text-5xl 2xl:text-7xl font-bold text-green1 flex'>
                        <span>Goals</span>
                    </div>
                    <br />
                    <div className='text-base'>
                        {singleProject.about.goals}
                    </div>
                </div>
                <div className='flex justify-center lg:justify-end'>
                    <div className='w-4/5'>
                        <img src={singleProject.about.image} />
                    </div>
                </div>
            </div>
        }
        {
            singleProject && singleProject.galery && singleProject.galery.length > 0 &&
            <div className='mt-20 flex justify-between px-4 lg:px-20'>
                <div className='w-full'>
                    <div className='text-3xl md:text-5xl 2xl:text-7xl font-bold flex w-full justify-center items-center'>
                        <div className='mr-4 w-1/4 md:w-auto'>
                            <img src={theme === "DARK"? singleProject.logoDark:singleProject.logoLight} />
                        </div>
                        <span>Gallery</span>
                    </div>
                    <br />
                    <div className='flex justify-start lg:justify-center w-full' style={{overflow:"auto"}}>
                        {
                            singleProject.galery.map((item, index)=>{
                                return (
                                    <div>
                                        <img key={index} src={item} className={`${singleProject.galery.length > 1? 'mr-4':''}`} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        }
        

        {/* <div className='absolute' style={{top:'25%', right:"10%"}}>
            <img src={cloud} />
        </div>
        <div className='absolute' style={{bottom:'15%', left:"-70px"}}>
            <img src={cloud} />
        </div>
        <div className='absolute' style={{bottom:'20%', right:"25%"}}>
            <img src={cloud} />
        </div> */}

        
        {
            singleProject && singleProject.achievement &&
            <div className='mt-20 relative' style={{backgroundColor:singleProject.color}} >
                <div className='absolute flex items-center px-4 lg:px-10 lg:py-20' style={{zIndex:2}}>
                    <div >
                        <div className='text-2xl mt-10 md:text-5xl 2xl:text-7xl font-bold flex'>
                            <span className='text-white'>What we <span style={{color:singleProject.secondaryColor}}>Reach?</span></span>
                        </div>
                        <div className='text-sm lg:flex'>
                            <div className='mt-4 lg:mt-20'>
                                <span className='text-secondaryYellow'>{singleProject.achievement.title1}</span>
                                <span className='block text-3xl md:text-5xl font-bold text-white'>{singleProject.achievement.value1}</span>
                            </div>
                            <div className='mt-4 lg:ml-10 lg:mt-20'>
                                <span className='text-secondaryYellow' >{singleProject.achievement.title2}</span>
                                <span className='block text-3xl md:text-5xl font-bold text-white'>{singleProject.achievement.value2}</span>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row justify-between mt-4 md:mt-10 lg:mt-20'>
                            <a href={singleProject.achievement.link[0]} target={'_blank'} className="w-1/2">
                                <img src={playstore}  />
                            </a>
                            <a href={singleProject.achievement.link[1]} target={'_blank'} className="w-1/2 lg:ml-10 mt-4 md:mt-0">
                                <img src={appstore}  />
                            </a>
                        </div>
                    </div>
                </div>
                <div className='relative' >
                    
                    <div className='relative w-full flex justify-end'>
                        <img src={singleProject.achievement.image} style={{minWidth:'500px'}} />
                    </div>
                </div>
            </div>
        }

        <div className='mt-20 flex flex-col-reverse lg:flex-row justify-between relative px-4 lg:px-20'>
            {/* <div className='absolute' style={{top:'20%', right:"5%"}}>
                <img src={cloudDark} />
            </div>
            <div className='absolute' style={{bottom:'-25%', right:"35%"}}>
                <img src={cloudDark} />
            </div> */}
            <div className='lg:w-1/2 flex justify-center items-end'>
                <div>
                    <div className='text-3xl md:text-5xl 2xl:text-7xl font-bold flex'>
                        <span>So wanna work with <span className='text-primaryOrange'>Embreo</span>? </span>
                    </div>
                    <br/>
                    <br/>
                    <div className='lg:w-1/2' >
                        <Link to={"/contact"} >
                            <CButton type={'primary'} className="text-center" onClick={()=>  history.push('/contact')} >
                                LET'S CHAT!
                            </CButton>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='flex justify-center lg:justify-end'>
                <div className='w-4/2' >
                    <img src={work.excited} />
                </div>
            </div>
        </div>
        <div onClick={()=> history.goBack()} className={`mt-28 px-4 lg:px-20 pb-20 flex ${theme === "LIGHT"?"text-primaryOrange":"text-secondaryYellow"}  items-center font-bold`} style={{cursor:'pointer'}}>
            <HiOutlineArrowNarrowLeft className='text-base' />
            <span className='text-base ml-2'>See Another Projects</span>
        </div>
    </div>
  )
}
