import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import axios from "axios";
import { ImSpinner2 } from "react-icons/im";
import { BiErrorCircle } from "react-icons/bi";

//Components
import Chatbox from "../../components/Chatbox/Chatbox";
import CModal from "../../components/Modals/CModal";
import CButton from "../../components/Button/CButton";
import CInput from "../../components/CInput/CInput";

//Actions
import { createUsersPost } from "../../../redux/actions/usersPost";

//images
import Dinosour from "../../../assets/images/ilustration/contact/DinasourLight.png";
import mailSent from "../../../assets/images/ilustration/contact/Animation_Form_Sent.png";

//configure Axios
const apiCall = axios.create({
  // baseURL:"http://localhost:4002",
  baseURL: process.env.REACT_APP_API_URL,
});

export default function Contact() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { partnershipPage } = useSelector((state) => state.theme.component);
  const { theme } = useSelector((state) => state.theme);
  const [sendingData, setSendingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    isShow: false,
    message: "",
  });

  const [sendData, setSendData] = useState({
    service: [],
    name: "",
    email: "",
    companyName: "",
    projectDesc: "",
  });

  const [showSentEmailNotif, setShowSentEMailNotif] = useState(false);

  const [sectionList, setSectionList] = useState([
    {
      sectionId: "brief",
      id: 1,
      show: true,
      className: "fadeIn",
    },
    {
      sectionId: "detail",
      id: 2,
      show: false,
      className: "fadeInBottomScroll",
    },
  ]);

  const onSendEmail = () => {
    setSendingData(true);
    apiCall
      .post("/emailClientRequest", sendData)
      .then((response) => {
        setShowSentEMailNotif(true);
        setSendingData(false);
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          setSendingData(false);
          const { service, email, name, projectDesc } =
            error.response.data.error;
          if ((service || email || name, projectDesc)) {
            setErrorMessage({
              isShow: true,
              message: "Please fill all mandatory field",
            });
          } else {
            setErrorMessage({ isShow: true, message: "ERROR" });
          }
        } else {
          setErrorMessage({ isShow: true, message: "ERROR" });
        }
      });

    // dispatch(createUsersPost({
    //     ...sendData,
    //     firstname: splitName[0],
    //     lastname: splitName[1] || "",
    // }))
    //setShowSentEMailNotif(true)
    // dispatch()
    // try {
    //     this.props.createUsersPost(this.state);
    //   } catch (e) {
    //     return swal({ text: 'your data failed send to admin', icon: 'warning' }, );
    //   }
    //   swal('your data saved').then((value) => {
    //     this.props.history.push('/');
    //   });
  };

  const handleClickServices = (item) => {
    const isItemExist = sendData.service.indexOf(item) >= 0;
    if (isItemExist) {
      const newData = sendData.service.filter((e) => e !== item);
      setSendData({ ...sendData, service: newData });
    } else {
      const newData = [...sendData.service, item];
      setSendData({ ...sendData, service: newData });
    }
  };

  return (
    <div className=" px-4 md:px-20 w-full  min-h-screen">
      <div className={" relative w-full min-h-screen flex pt-20"}>
        <div className="w-full">
          <div
            onClick={() => history.goBack()}
            className={`mt-10 flex ${
              theme === "LIGHT" ? "text-primaryOrange" : "text-secondaryYellow"
            }  items-center font-bold`}
            style={{ cursor: "pointer" }}
          >
            <HiOutlineArrowNarrowLeft className="text-base" />
            <span className="text-base ml-2">Back</span>
          </div>
          <div className="mt-10 lg:flex flex-row-reverse justify-between">
            <div className="flex w-full lg:w-1/2 justify-end p-0 md:p-10">
              <div className="flex items-center">
                <div className="w-full">
                  <img src={Dinosour} style={{ width: "100%" }} />
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 mt-10">
              <div
                className="text-3xl md:text-5xl 2xl:text-7xl font-bold"
                style={{ lineHeight: "55px" }}
              >
                Let's get that{" "}
                <span className="text-primaryOrange">product launched</span>
                {/* <br/> */}
                {/* <span className='text-primaryOrange' >Something Cool</span> */}
              </div>
              {/* <br />
                        <div className='text-base'>
                            Embreo provide three types of partnership models include clients, business partner, and refferal partner.
                        </div> */}
              <br />
              <br />
              <span className="text-lg font-bold">
                What are you looking to do?
              </span>
              <br />
              <span className="text-base font-bold text-primaryOrange">
                You can choose multiple services
              </span>
              <br />
              <div className="flex flex-wrap ">
                {[
                  "Build me a product!",
                  "Build a business with me!",
                  "Supporting the ecosystem",
                  "Others",
                ].map((item, index) => {
                  return (
                    <div key={index}>
                      <CButton
                        onClick={() => handleClickServices(item)}
                        type={"tertiary"}
                        className=" px-0 md:px-8 mr-2 md:mr-2 mt-4 flex justify-center"
                        style={{
                          backgroundColor:
                            sendData.service.indexOf(item) >= 0
                              ? "#FF7360"
                              : null,
                          color:
                            sendData.service.indexOf(item) >= 0
                              ? "white"
                              : null,
                        }}
                      >
                        {item}
                      </CButton>
                    </div>
                  );
                })}
              </div>
              <br />
              <br />
              <div>
                <div className="md:flex">
                  <CInput
                    value={sendData.name || ""}
                    label={"Name"}
                    id={"name"}
                    onChange={(e) =>
                      setSendData({ ...sendData, name: e.target.value })
                    }
                  />
                  <CInput
                    value={sendData.email || ""}
                    label={"Email"}
                    id={"email"}
                    className="mt-4 md:mt-0 md:ml-8 "
                    onChange={(e) =>
                      setSendData({ ...sendData, email: e.target.value })
                    }
                    style={{ flexGrow: "1" }}
                  />
                </div>
                <br />
                <br />
                <div className="flex">
                  <CInput
                    value={sendData.companyName || ""}
                    label={"Company Name"}
                    optional={true}
                    id={"company"}
                    onChange={(e) =>
                      setSendData({ ...sendData, companyName: e.target.value })
                    }
                    className={"w-full items-end"}
                  />
                </div>
                <br />
                <br />
                <div>
                  <CInput
                    value={sendData.projectDesc || ""}
                    label={"Project Description"}
                    textArea={true}
                    id={"description"}
                    onChange={(e) =>
                      setSendData({ ...sendData, projectDesc: e.target.value })
                    }
                    placeholder={"Write your project details"}
                  />
                </div>
                <div className="w-full flex justify-end pb-10">
                  <CButton
                    onClick={() => (sendingData ? null : onSendEmail())}
                    type={"primary"}
                    className="px-10"
                    style={{
                      width: "7em",
                      height: "50px",
                      borderRadius: "30px",
                    }}
                  >
                    <div className={`flex items-center justify-center`}>
                      {sendingData ? (
                        <ImSpinner2
                          className="animate-spin"
                          style={{ fontSize: "22px", color: "#FFFFFF" }}
                        />
                      ) : (
                        "Send"
                      )}
                    </div>
                  </CButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CModal
        openModal={errorMessage.isShow}
        onClickClose={() => setShowSentEMailNotif(false)}
        overlayBackgorund={"rgb(0,0,0,0.2)"}
      >
        <div
          className={`${
            theme === "LIGHT"
              ? "bg-white text-black"
              : "bg-darkModeTheme text-white"
          } p-10 pt-4 rounded-lg`}
          style={{ maxWidth: "400px" }}
        >
          <div className="flex flex-col items-center">
            <BiErrorCircle style={{ fontSize: "100px", color: "#d63031" }} />
            <span className="mt-2 text-center" style={{ fontSize: "25px" }}>
              {errorMessage.message}
            </span>
            <br />
            <CButton
              onClick={() => {
                setErrorMessage({ isShow: false, message: "" });
              }}
              type={"primary"}
              className="px-10"
              style={{ width: "fit-content", borderRadius: "30px" }}
            >
              <div className="flex items-center">OK</div>
            </CButton>
          </div>
        </div>
      </CModal>
      <CModal
        openModal={showSentEmailNotif}
        onClickClose={() => setShowSentEMailNotif(false)}
        overlayBackgorund={"rgb(0,0,0,0.2)"}
      >
        <div
          className={`${
            theme === "LIGHT"
              ? "bg-white text-black"
              : "bg-darkModeTheme text-white"
          } p-10 pt-4 rounded-lg`}
          style={{ maxWidth: "400px" }}
        >
          <div className="flex flex-col items-center">
            <img src={mailSent} />
            <span
              className={`${
                theme === "DARK" ? "text-accentGreen" : "text-primaryOrange"
              } text-lg font-bold mt-6`}
            >
              Your request has been sent!
            </span>
            <span className="mt-2">We will contact you soon</span>
            <br />
            <CButton
              onClick={() => {
                setShowSentEMailNotif(false);
                setSendData({
                  service: [],
                  name: "",
                  email: "",
                  companyName: "",
                  projectDesc: "",
                });
              }}
              type={"primary"}
              className="px-10"
              style={{ width: "fit-content", borderRadius: "30px" }}
            >
              <div className="flex items-center">Close</div>
            </CButton>
          </div>
        </div>
      </CModal>
    </div>
  );
}
