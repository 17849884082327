import React, {useEffect, useState} from 'react'
import './style.css'

export default function ({sendProgressValue, currentProggressValue}) {

    const [progressValue, setProgressValue] = useState(currentProggressValue)

    const progress = [
        {
            label:'Research',
            value:"R"
        },
        {
            label:'Objective',
            value: "O"
        },
        {
            label:'Building',
            value:"B"
        },
        {
            label:'Operate',
            value: "O"
        },
        {
            label:'Transfer',
            value: "T"
        }, 
    ]

    const handleProgress = (value) =>{
        setProgressValue(value+1)
        sendProgressValue(value+1)
    }
    
    return (
        <div className='flex flex-col '>
            {
                progress.map((el,i)=>{
                    console.log('see progressValue, i, progressValue > i+1', progressValue, i, progressValue < i+1, )
                    return (
                        <div key={i} className='flex'>
                            <div className='w-20'>
                                <p style={{fontWeight: 500, color: progressValue < i+1? "#F2F2F2":null}}>{el.label}</p>
                            </div>
                            <div className='flex flex-col items-center'>
                                <div onClick={()=> handleProgress(i)} className='progress-circle' style={{backgroundColor:progressValue < i+1? "#F2F2F2":"#FF7360"}}>
                                    {el.value}
                                </div>
                                {
                                    i < 4 && 
                                    <div className='progress-line' style={{backgroundColor:progressValue < i+2? "#F2F2F2":null}}>

                                    </div>
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
