//Team Image
import Kit from '../../../assets/images/ilustration/Team/Kit.png'
import Alvin from '../../../assets/images/ilustration/Team/Alvin.png'
import Anton from '../../../assets/images/ilustration/Team/Anton.png'
import Chika from '../../../assets/images/ilustration/Team/Chika.png'
import Ferdy from '../../../assets/images/ilustration/Team/Ferdy.png'
import Haryo from '../../../assets/images/ilustration/Team/Haryo.png'
import Ihsan from '../../../assets/images/ilustration/Team/Ihsan.png'
import Jeffrey from '../../../assets/images/ilustration/Team/Jeffrey.png'
import Joshua from '../../../assets/images/ilustration/Team/Joshua.png'
import Mathius from '../../../assets/images/ilustration/Team/Mathius.png'
import Ramadhanu from '../../../assets/images/ilustration/Team/Ramadhanu.png'
import Rizqon from '../../../assets/images/ilustration/Team/Rizqon.png'
import Tio from '../../../assets/images/ilustration/Team/Tio.png'
import Yudhist from '../../../assets/images/ilustration/Team/Yudhist.png'

const teamData = [
    {
        name: "Kit",
        role:"Founder",
        image:Kit,
    },
    {
        name: "Joshua",
        role:"Project Manager",
        image:Joshua,
    },
    {
        name: "Antonius",
        role:"Product Associate",
        image:Anton,
    },
    {
        name: "Alvin",
        role:"Product Associate",
        image:Alvin,
    },
    {
        name: "Yudhist",
        role:"QA Engineer",
        image:Yudhist,
    },
    {
        name: "Jeffrey",
        role:"Tech Partner",
        image:Jeffrey,
    },
    {
        name: "Ferdy",
        role:"Fullstack Engineer",
        image:Ferdy,
    },
    {
        name: "Mathius",
        role:"Fullstack Engineer",
        image:Mathius,
    },
    {
        name: "Ihsan",
        role:"Fullstack Engineer",
        image:Ihsan,
    },
    {
        name: "Rizqon",
        role:"Fullstack Engineer",
        image:Rizqon,
    },
    {
        name: "Ramadhanu",
        role:"Fullstack Engineer",
        image:Ramadhanu,
    },
    {
        name: "Chika",
        role:"Fullstack Engineer",
        image:Chika,
    },
    {
        name: "Tio",
        role:"UI/UX Designer",
        image:Tio,
    },
    {
        name: "Haryo",
        role:"UI/UX Designer",
        image:Haryo,
    },
]

export default teamData