//Light Image
//Research
import CategorizeLight from '../../../assets/images/ilustration/robot/Research/CategorizeLight.png' 
import GraphLight from '../../../assets/images/ilustration/robot/Research/GraphLight.png' 
import MarketLight from '../../../assets/images/ilustration/robot/Research/MarketLight.png' 

//Objective
import DesignLight from '../../../assets/images/ilustration/robot/Objective/DesignLight.png' 
import GroupLight from '../../../assets/images/ilustration/robot/Objective/GroupLight.png' 
import ProjectLight from '../../../assets/images/ilustration/robot/Objective/ProjectLight.png' 
import PrototypeLight from '../../../assets/images/ilustration/robot/Objective/PrototypeLight.png' 
import UsabilityLight from '../../../assets/images/ilustration/robot/Objective/UsabilityLight.png'
import WorkflowLight from '../../../assets/images/ilustration/robot/Objective/wrokflow.png' 

//Build
import BusinessLight from '../../../assets/images/ilustration/robot/Build/BusinessLight.png'
import CycleLight from '../../../assets/images/ilustration/robot/Build/CycleLight.png'
import SecurityLight from '../../../assets/images/ilustration/robot/Build/SecurityLight.png'
import TimelineLight from '../../../assets/images/ilustration/robot/Build/TimelineLight.png'

//Operate
import ApplicationLight from '../../../assets/images/ilustration/robot/Operate/ApplicationLight.png'
import BugLight from '../../../assets/images/ilustration/robot/Operate/BugLight.png'
import ImproveLight from '../../../assets/images/ilustration/robot/Operate/ImproveLight.png'
import StickyLight from '../../../assets/images/ilustration/robot/Operate/StickyLight.png'
import UpgradeLight from '../../../assets/images/ilustration/robot/Operate/UpgradeLight.png'

//Transfer
import DevelopLight from '../../../assets/images/ilustration/robot/Transfer/DevelopLight.png'
import ProductLight from '../../../assets/images/ilustration/robot/Transfer/ProductLight.png'
import TransitionLight from '../../../assets/images/ilustration/robot/Transfer/TransitionLight.png'

//Robot Component
import RobotHead from '../../../assets/images/ilustration/robot/Research/robotHead.png'
import RobotBody from '../../../assets/images/ilustration/robot/Objective/RobotBody.png'
import RobotFull from '../../../assets/images/ilustration/robot/Build/RobotFull.png'
import RobotOperate from '../../../assets/images/ilustration/robot/Operate/RobotOperate.png'
import RobotTransfer from '../../../assets/images/ilustration/robot/Transfer/RobotTransfer.png'


const robotData = [
    {
        label: "Research",
        robotComp:RobotHead,
        firstDescription:"Whether you\'re starting from 0 or have your idea down on paper, we are here to incubate. Embarking on a digital transformation can be scary, where do I start? How can my business evolve? We come in from a consultative angle to help you refine what you need. That\'s right! We do the research WITH you. We run design thinking workshops with you, develop and align on a business model to really dive deep into your customer\'s mind.",
        secondDescription:null,
        result:[
            {
                lightIcon: MarketLight,
                darkIcon:'',
                label:'Customer Deep-dive'
            },
            {
                lightIcon:CategorizeLight,
                darkIcon:'',
                label:'User Behavior Analysis'
            },
            {
                lightIcon:GraphLight,
                darkIcon:'',
                label:'Key market analysis'
            },
        ]
    },
    {
        label: "Objective",
        robotComp:RobotBody,
        firstDescription:"What do you do with all that research? Pen them down and test them of course! We work with you to test the assumptions during the research journey to reduce business risk.",
        secondDescription:"We utilize tools and technology to help us quantify demand for such solutions to ensure hit rate of all our projects.",
        result:[
            {
                lightIcon:DesignLight,
                darkIcon:'',
                label:'UI/UX Design'
            },
            {
                lightIcon:WorkflowLight,
                darkIcon:'',
                label:'Workflow Design & Analysis'
            },
            {
                lightIcon:PrototypeLight,
                darkIcon:'',
                label:'Apps Prototyping'
            },
            {
                lightIcon:UsabilityLight,
                darkIcon:'',
                label:'Usability test'
            },
            {
                lightIcon:GroupLight,
                darkIcon:'',
                label:'Focus Group test'
            },
            {
                lightIcon:ProjectLight,
                darkIcon:'',
                label:'Project Scoping'
            },
        ]
    },
    {
        label: "Build",
        robotComp:RobotFull,
        firstDescription:"Once we have supporting evidence that the idea is likely to be a success, we will begin our product creation journey!",
        secondDescription:"We will employ a list of development methodologies to maintain flexibility of project scope, with tight and open communications. We believe heavily in conflict management and relationship building, as no project is ever free of conflict.",
        result:[
            {
                lightIcon:CycleLight,
                darkIcon:'',
                label:'Flexible development cycles'
            },
            {
                lightIcon:SecurityLight,
                darkIcon:'',
                label:'Secure Development'
            },
            {
                lightIcon:TimelineLight,
                darkIcon:'',
                label:'Timeline expectation'
            },
            {
                lightIcon:BusinessLight,
                darkIcon:'',
                label:'Effective business planning'
            },
        ]
        
    },
    {
        label: "Operate",
        robotComp:RobotOperate,
        firstDescription:"Our product is live! What\'s next? Depending on the resources of your business, we develop an operating model to help you achieve and maintain your commitments to your customers.",
        secondDescription:"This can be a co-shared maintenance responsibility or even fully on us. Our objective is to ensure customers of the product stick to the platform and consistently use it.",
        result:[
            {
                lightIcon:ImproveLight,
                darkIcon:'',
                label:'Operations of product'
            },
            {
                lightIcon:ApplicationLight,
                darkIcon:'',
                label:'Application uptime'
            },
            {
                lightIcon:BugLight,
                darkIcon:'',
                label:'Bug fixing & complaints'
            },
            {
                lightIcon:UpgradeLight,
                darkIcon:'',
                label:'Upgrading of application and frameworks'
            },
            {
                lightIcon:StickyLight,
                darkIcon:'',
                label:'Creating customer stickiness'
            },
        ]
    },
    {
        label: "Transfer",
        robotComp:RobotTransfer,
        firstDescription:"Sustainability and legacy planning is an integral component of every product that walks through our doors. As such, our partners cannot be reliant on us forever as their business scales further.",
        secondDescription:"In situations like this, we put in place a succession plan so that our product can be inherited by the next team whether it be owned inhouse by our clients or through us. We support and help our partners develop their own capabilities with time",
        result:[
            {
                lightIcon:TransitionLight,
                darkIcon:'',
                label:'Transition & succession planning'
            },
            {
                lightIcon:DevelopLight,
                darkIcon:'',
                label:'Capability development'
            },
            {
                lightIcon:ProductLight,
                darkIcon:'',
                label:'Product longevity'
            },
        ]
    },
]

export default robotData