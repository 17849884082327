import React, {useEffect} from 'react';
import {useHistory, Link} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import topLogo from '../../assets/images/top-logo.png';
import logoDark from '../../assets/logo-dark.png'
import './style.css';

//Actions
import {toggleDarkMode} from '../../redux/actions/theme'

//Component
import CButton from '../components/Button/CButton';

const Headers = ({transparent}) =>{

  const dispatch = useDispatch()
  const history = useHistory()

  const theme = useSelector(state=> state.theme.theme)

  useEffect(()=>{
    const mode =  localStorage.getItem('mode')
    dispatch(toggleDarkMode(mode))
  },[])


  const handleToggleDarkMode = () =>{
    localStorage.setItem('mode', theme === "DARK"?"LIGHT":"DARK")
    dispatch(toggleDarkMode(theme === "DARK"?"LIGHT":"DARK"))
  }

  return (
    <div className={`flex justify-between px-4 md:px-20 py-5 w-full fixed ${ theme === "DARK"? "bg-darkModeTheme": "bg-white"}`} style={{zIndex:9999, backgroundColor: transparent? 'transparent': null}}>
      <div onClick={()=> history.push('/home')} style={{cursor:'pointer'}} >
        <img src={theme === "DARK"? logoDark: topLogo} />
      </div>
      <div className='flex items-center justify-around w-2/3 md:w-1/2 lg:w-1/3'>
        <div onClick={()=> handleToggleDarkMode()} className={"label"} style={{marginBottom:'0px'}}>
          <div className={theme === "LIGHT"?'ball':"ball-dark"}>{theme === "DARK"? "Dark":"Light" }</div>
        </div>
        <Link to={"/contact"} >
          <CButton type={ theme === "DARK"? 'secondary':'primary'} className="ml-3 px-4 py-2 text-sm inter-font">
            Get in touch
          </CButton>
        </Link>
      </div>
    </div>
  )
}

export default Headers;
