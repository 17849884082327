import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'
import {FaRegLightbulb} from 'react-icons/fa'

//Components
import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation'
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop'
import Chatbox from '../../components/Chatbox/Chatbox'


//Images
import ideation1Image from '../../../assets/images/ilustration/Offers/ideation-image.png'
import ideation2Image from '../../../assets/images/ilustration/Offers/ideation-image-2.png'
import ideation3Image from '../../../assets/images/ilustration/Offers/ideation-image-3.png'

import website1Image from '../../../assets/images/ilustration/Offers/webiste-image-1.png'
import website2Image from '../../../assets/images/ilustration/Offers/webiste-image-2.png'
import website3Image from '../../../assets/images/ilustration/Offers/webiste-image-3.png'

import mobile1Image from '../../../assets/images/ilustration/Offers/mobile-image-1.png'
import mobile2Image from '../../../assets/images/ilustration/Offers/mobile-image-2.png'
import mobile3Image from '../../../assets/images/ilustration/Offers/mobile-image-3.png'

import branding1Image from '../../../assets/images/ilustration/Offers/branding-image-1.png'
import branding2Image from '../../../assets/images/ilustration/Offers/branding-image-2.png'
import branding3Image from '../../../assets/images/ilustration/Offers/branding-image-3.png'

import consulting1Image from '../../../assets/images/ilustration/Offers/consulting-image-1.png'
import consulting2Image from '../../../assets/images/ilustration/Offers/consulting-image-2.png'
import consulting3Image from '../../../assets/images/ilustration/Offers/consulting-image-3.png'

export default function Offers() {

    const history = useHistory()

    const {partnershipPage, offersPage} = useSelector(state=> state.theme.component)
    const {theme} = useSelector(state=> state.theme)

    const [sectionList, setSectionList] = useState([
        {
            sectionId:"brief",
            id:1,
            show: true, 
            className:'fadeIn'
        },
        {
            sectionId:"detail",
            id:2,
            show: false,
            className:'fadeInBottomScroll'
        },
    ])

    const triggerScroll = (event) =>{
        if (sectionList.filter(el=> el.id === 1)[0].show && event.deltaY < 0) {
            return null
        }

        if (sectionList.filter(el=> el.id === 2)[0].show) {
            return null
        }

        setSectionList([
            {
                sectionId:"brief",
                id:1,
                show: true, 
                className:'fadeOutBottomScroll'
            },
            {
                sectionId:"detail",
                id:2,
                show: true,
                className:'fadeInBottomScroll'
            },
        ])

        event.persist();

        setTimeout(()=> {
            setSectionList([
                {
                    sectionId:"brief",
                    id:1,
                    show: false, 
                    className:'fadeIn'
                },
                {
                    sectionId:"detail",
                    id:2,
                    show: true,
                    className:'fadeInBottomScroll'
                },
            ])
        }, 1000)
    }

    const handleToTop = () =>{

        const defaultSectionList = [
            {
                sectionId:"brief",
                id:1,
                show: true, 
                className:'fadeIn'
            },
            {
                sectionId:"detail",
                id:2,
                show: false,
                className:'fadeInBottomScroll'
            },
        ]

        setSectionList(defaultSectionList)
    }

  return (
    <div className='px-4 md:px-20 min-h-screen' onWheel={(event)=> triggerScroll(event)} >
        {   sectionList.filter(el=> el.id === 1)[0].show && <ScrollAnimation onClick={(event)=> triggerScroll(event)} />}
        <Chatbox onClick={()=>history.push('/contact')}/>
            <div className={' relative pt-96 lg:pt-0 h-screen flex items-center'}>
                <div>
                    <div onClick={()=> history.goBack()} className={`mt-10 flex ${theme === "LIGHT"?"text-primaryOrange":"text-secondaryYellow"}  items-center font-bold`} style={{cursor:'pointer'}}>
                        <HiOutlineArrowNarrowLeft className='text-base' />
                        <span className='text-base ml-2'>Back</span>
                    </div>
                    <div className='mt-20 lg:flex lg:flex-row-reverse justify-between'>
                        <div className='flex justify-center'>
                            <div>
                                <img src={offersPage.offers} />
                            </div>
                        </div>
                        <div className='lg:w-1/2 mt-10 lg:mt-0'>
                            <div className='text-3xl md:text-4xl 2xl:text-7xl font-bold'>
                                How Embreo helps you grow
                            </div>
                            <br />
                            <div className='text-base'>
                                We drive your business growth with bespoke solutions, whether you have an existing idea or none.

                            </div>
                            <br/>
                            <br/>
                            <div className={`text-base font-bold ${theme === "DARK"? "text-secondaryYellow": "text-accentGreen"}`}>
                                Embreo Services
                            </div>
                            <br/>
                            <div className='flex flex-wrap'>
                                <div className='flex mt-4 md:mt-4  items-center px-4'>
                                    <div className='w-8'>
                                        {/* <FaRegLightbulb style={{fontSize:"30px"}}/> */}
                                        <img src={offersPage.ideation} />
                                    </div>
                                    <span className='text-base ml-4' >Ideation</span>
                                </div>
                                <div className='flex mt-4 md:mt-4  items-center px-4 md:border-l-2 lg:border-0 xl:border-l-2 '>
                                    <div className='w-8'>
                                        <img src={offersPage.website} />
                                    </div>
                                    <span className='text-base ml-4' >UX Design</span>
                                </div>
                                <div className='flex mt-4 md:mt-4  items-center px-4 md:border-l-2 lg:border-0 xl:border-l-2 '>
                                    <div className='w-8'>
                                        <img src={offersPage.branding} />
                                    </div>
                                    <span className='text-base ml-4' >Application Development</span>
                                </div>
                                <div className='flex mt-4 md:mt-4  items-center px-4 md:border-l-2 lg:border-0 xl:border-l-2'>
                                    <div className='w-8'>
                                        <img src={offersPage.mobile} />
                                    </div>
                                    <span className='text-base ml-4' >Quality Assurance</span>
                                </div>
                                <div className='flex mt-4 md:mt-4 items-center px-4 md:border-l-2 lg:border-0 xl:border-l-2'>
                                    <div className='w-8'>
                                        <img src={offersPage.consulting} />
                                    </div>
                                    <span className='text-base ml-4' >Post-launch</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        <div className={" relative"}>
            {/* Section 2 */}
            <div className='pt-96 md:pt-82 lg:pt-20  lg:flex justify-between'>
                <div className='flex justify-end'>
                    <div className=' flex'>
                        <div className='mr-4'>
                            <div className='relative flex w-32 h-1/3'>
                                <div className='w-20' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute w-32' style={{top:'40px', left:'0px'}}>
                                    <img src={ideation3Image} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='relative flex justify-end w-48'>
                                <div className='w-20 pt-4' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute' style={{top:'0px', right:'40px'}}>
                                    <img src={ideation1Image} />
                                </div>
                            </div>
                            <div className='relative flex items-end w-48 h-56 mt-14'>
                                <div className='w-20 pl-4' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute w-48' style={{top:'0px', left:'0px'}}>
                                    <img src={ideation2Image} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full lg:w-1/2'>
                    <div className='text-xl text-primaryOrange font-bold'>
                        Ideation {"&"} Incubation
                    </div>
                    <br />
                    <div className='text-3xl md:text-4xl 2xl:text-7xl font-bold'>
                        Transforming your organization's future
                    </div>
                    <br />
                    <div className='text-base'>
                        By deep diving into your business and customers, we develop an understanding of your organizational success pillars.
                        <br/>
                        <br/> 
                        With that in mind, we work with you to map out business processes and value delivery journey, highlighting areas that can be made efficient or innovated upon. 
                    </div>
                    <br/>
                    <div className='flex justify-center'>
                        <img src={partnershipPage.brushLine} />
                    </div>
                </div>
            </div>
            {/* Section 1 */}
            <div className='pt-20 flex flex-col-reverse lg:flex-row justify-between'>
                <div className='lg:w-1/2'>
                    <div className='text-xl text-primaryOrange font-bold'>
                        UI/UX Design
                    </div>
                    <br />
                    <div className='text-3xl md:text-4xl 2xl:text-7xl font-bold'>
                        Tailored Customer Experience
                    </div>
                    <br />
                    <div className='text-base'>
                        Our design thinking process ensures we approach all our user designs from a customer-first perspective.
                        <br/>
                        <br/>
                        Using our ROBOT methodology, we utilize research to craft tailored experiences for your key target market.
                    </div>
                    <br/>
                    <div className='flex justify-center'>
                        <img src={partnershipPage.brushLine} />
                    </div>
                </div>
                <div className='flex justify-center mt-10 lg:mt-0'>
                    <div className=' flex'>
                        <div>
                            <div className='relative w-48'>
                                <div className='w-20 pt-4' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute' style={{top:'0px', left:'40px'}}>
                                    <img src={website1Image} />
                                </div>
                            </div>
                            <div className='relative flex justify-end items-end w-48 h-56 mt-14'>
                                <div className='w-20 pr-4' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute ml-8 md:ml-0 w-40 md:w-48' style={{top:'0px', left:'0px'}}>
                                    <img src={website2Image} />
                                </div>
                            </div>
                        </div>
                        <div className='ml-4'>
                            <div className='relative flex justify-end w-32 h-1/3'>
                                <div className='w-20' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute w-32' style={{top:'40px', left:'0px'}}>
                                    <img src={website3Image} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section 2 */}
            <div className='mt-20 lg:flex justify-between'>
                <div className='flex justify-end'>
                    <div className=' flex'>
                        <div className='mr-4'>
                            <div className='relative flex w-32 h-1/3'>
                                <div className='w-20' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute w-32' style={{top:'40px', left:'0px'}}>
                                    <img src={mobile3Image} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='relative flex justify-end w-48'>
                                <div className='w-20 pt-4' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute' style={{top:'0px', right:'40px'}}>
                                    <img src={mobile1Image} />
                                </div>
                            </div>
                            <div className='relative flex items-end w-48 h-56 mt-14'>
                                <div className='w-20 pl-4' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute w-48' style={{top:'0px', left:'0px'}}>
                                    <img src={mobile2Image} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full lg:w-1/2'>
                    <div className='text-xl text-primaryOrange font-bold'>
                        Application Development
                    </div>
                    <br />
                    <div className='text-3xl md:text-4xl 2xl:text-7xl font-bold'>
                        Business Oriented Development
                    </div>
                    <br />
                    <div className='text-base'>
                        Product development should be driven by business objectives and priorities.
                        <br/>
                        <br/> 
                        We drive to strike a balance between scalable architectures, project scope and technical compromises. Ensuring that your organization has a working product that can scale within your budget and timelines.
                    </div>
                    <br/>
                    <div className='flex justify-center'>
                        <img src={partnershipPage.brushLine} />
                    </div>
                </div>
            </div>

            {/* Section 3 */}
            <div className='pt-20 flex flex-col-reverse lg:flex-row justify-between'>
                <div className='lg:w-1/2'>
                    <div className='text-xl text-primaryOrange font-bold'>
                        Quality Assurance
                    </div>
                    <br />
                    <div className='text-3xl md:text-4xl 2xl:text-7xl font-bold'>
                        Managing Digital Risks
                    </div>
                    <br />
                    <div className='text-base'>
                        A digital product introduces new risks to an organization. We help you manage these digital risks by providing vulnerability assessments, managing of cyber security dataflow and implementing a stringent testing regime.
                        <br/>
                        <br/>
                        We also tie up with partners to provide you with security audits and advisory up to international standards {"(ISO, GDPR, PDPA et al)"}.
                    </div>
                    <br/>
                    <div className='flex justify-center'>
                        <img src={partnershipPage.brushLine} />
                    </div>
                </div>
                <div className='flex justify-center mt-10 lg:mt-0'>
                    <div className=' flex'>
                        <div>
                            <div className='relative w-48'>
                                <div className='w-20 pt-4' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute' style={{top:'0px', left:'40px'}}>
                                    <img src={branding1Image} />
                                </div>
                            </div>
                            <div className='relative flex justify-end items-end w-48 h-56 mt-14'>
                                <div className='w-20 pr-4' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute ml-8 md:ml-0 w-40 md:w-48' style={{top:'0px', left:'0px'}}>
                                    <img src={branding2Image} />
                                </div>
                            </div>
                        </div>
                        <div className='ml-4'>
                            <div className='relative flex justify-end w-32 h-1/3'>
                                <div className='w-20' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute w-32' style={{top:'40px', left:'0px'}}>
                                    <img src={branding3Image} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section 4 */}
            <div className='mt-20 lg:flex justify-between'>
                <div className='flex justify-end'>
                    <div className=' flex'>
                        <div className='mr-4'>
                            <div className='relative flex w-32 h-1/3'>
                                <div className='w-20' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute w-32' style={{top:'40px', left:'0px'}}>
                                    <img src={consulting3Image} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='relative flex justify-end w-48'>
                                <div className='w-20 pt-4' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute' style={{top:'0px', right:'40px'}}>
                                    <img src={consulting1Image} />
                                </div>
                            </div>
                            <div className='relative flex items-end w-48 h-56 mt-14'>
                                <div className='w-20 pl-4' >
                                    <img src={partnershipPage.dot} />
                                </div>
                                <div className='absolute w-48' style={{top:'0px', left:'0px'}}>
                                    <img src={consulting2Image} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full lg:w-1/2'>
                    <div className='text-xl text-primaryOrange font-bold'>
                        Post-Launch Support
                    </div>
                    <br />
                    <div className='text-3xl md:text-4xl 2xl:text-7xl font-bold'>
                        Ensuring Succession and Longevity
                    </div>
                    <br />
                    <div className='text-base'>
                        At Embreo, we are committed to every product launched through our platform. As such, we work with you to put in place succession plans.
                        <br/>
                        <br/>
                        Whether it be continual maintenance and support by Embreo or the development of an inhouse tech team, we work with you to plan for future operability of our products.
                    </div>
                    <br/>
                    <div className='flex justify-center'>
                        <img src={partnershipPage.brushLine} />
                    </div>
                </div>
            </div>
            {/* <ScrollToTop onClick={()=> window.scroll(0,0)}/> */}
        </div>
    </div>
  )
}
