import React, {useEffect}  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import Headers from './components/headers';
import Footers from './components/footers'
// import Footers from './components/footers';
import Welcome from './components/pages/welcome';
import Home from './components/pages/home'
import Partnership from './components/pages/partnerships'
import Offers from './components/pages/offer'
import Works from './components/pages/ourworks'
import DetailProject from './components/pages/detailProject'
import Robot from './components/pages/robot'
import Office from './components/pages/office'
import Team from './components/pages/team'
import Contact from './components/pages/contact'
import PrivacyPolicy from './components/pages/privacyPolicy'

//Style
import './styles/main.css'
import './styles/global.css'


const App = () =>{

  const theme = useSelector(state => state.theme)
  const history = useHistory()

  // useEffect(()=>{

  // },[])

  return (
    <div className={`App ${theme.theme === "DARK"? 'bg-darkModeTheme text-white': "bg-white"}`}>
        <Router>
          <ScrollMemory />
          <Route exact path="/"  >
            <Headers />
            <Welcome/>
          </Route>
          <Route exact path="/home" >
            <Headers />
            <Home/>
            {/* <Footers /> */}
          </Route>
          <Route exact path="/partnership"  >
            <Headers />
            <Partnership/>
            <Footers />
          </Route>
          <Route exact path="/offers" >
            <Headers />
            <Offers/>
            <Footers />
          </Route>
          <Route exact path="/works" >
            <Headers transparent={true} />
            <Works/>
            <Footers />
          </Route>
          <Route exact path="/works/:id"  >
            <Headers />
            <DetailProject/>
            <Footers />
          </Route>
          <Route exact path="/robot"  >
            <Headers />
            <Robot/>
            <Footers />
          </Route>
          <Route exact path="/office" >
            <Headers />
            <Office/>
            <Footers />
          </Route>
          <Route exact path="/team"  >
            <Headers />
            <Team/>
            <Footers />
          </Route>
          <Route exact path="/contact"  >
            <Headers />
            <Contact/>
            <Footers />
          </Route>
          <Route exact path="/privacy-policy" >
            <Headers />
            <PrivacyPolicy/>
            <Footers />
          </Route>
          {/* { <Footers />} */}
        </Router>
        {/* <style>{
          `
            @font-face {
              font-family: DINPro;
              src: url(${DINProLight});
            }
            @font-face {
              font-family: DINPro;
              src: url(${DINProBold});
              font-weight: bold;
            }

            body, button, input, select, textarea {
              font-family: DINPro;
              color: ${Color.black}
            }
          `
        }
        </style> */}
      </div>
  )
}

export default App;