import React from 'react'
import {BsFillArrowUpCircleFill} from 'react-icons/bs'
import './style.css'
import chatboxIcon from '../../../assets/images/Chatbox.png'
import { Link } from 'react-router-dom'

export default function Chatbox({onClick}) {
  return (
    <div className='fixed checkbox-position'>
      <Link to={"/contact"}>
        <div className='flex items-center'>
          <div className='mr-3 w-10 md:w-20' style={{cursor:'pointer'}}  >
            <img src={chatboxIcon} />
          </div>
          {/* <span className='text-sm' onClick={onClick} style={{cursor:'pointer'}} >Let’s chat</span> */}
        </div>
      </Link>
    </div>
  )
}
