import React, { useEffect, useState, useRef } from 'react'
import { useHistory, Link, useLocation, Prompt } from 'react-router-dom'
import './style.css'
// import Lottie from 'react-lottie'
import { useScrollDirection } from 'react-use-scroll-direction'
import { useSelector } from 'react-redux'
import {HiOutlineArrowNarrowRight} from 'react-icons/hi'

//Components
import CButton from '../../components/Button/CButton'
import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation'
import Chatbox from '../../components/Chatbox/Chatbox'
import Footer from '../../footers/index'

//Animation
import personFlying from '../../../assets/images/ilustration/HomePage/personFlying-Light.gif'
import personFlyingDark from '../../../assets/images/ilustration/HomePage/personFlying-dark.gif'

export default function Home() {

    const history = useHistory()
    // const location = useLocation()

    const {homePage} = useSelector(state => state.theme.component)
    const {theme} = useSelector(state => state.theme)

    const { 
        isScrolling,
        isScrollingX,
        isScrollingY,
        isScrollingUp, 
        isScrollingDown,
        isScrollingLeft,
        isScrollingRight,
        scrollDirection,
      } = useScrollDirection()

    

    // useEffect(()=>{
    //     return ()=>{
    //         setTimeout(()=>{

    //         },5000) 
    //     }
    // },[])
 
    const [isBlockRoute, setIsBlockRoute] = useState(true)
    const [onRunning, setOnRunning] = useState(false)
    const handleWannaWorkWithEmbreo = () =>{
        // setIsBlockRoute(true)
        return null
    }

    useEffect(()=>{
        if (!isBlockRoute) {
            setOnRunning(true)
            setTimeout(()=>{
                setOnRunning(false)
                history.push("/office")
            },1000)
        }
    },[isBlockRoute])

    useEffect(()=>{
        return ()=>{
            setIsBlockRoute(true)
        }
    },[])

  let opacity = 0;



  if (window) {
    let scrollY = window.scrollY
    // console.log('see window height', window.screen.availHeight)
    // console.log('see window width', window.screen.availWidth)
    // console.log('see window scroll', scrollY)
    if ( window.screen.availWidth < 640) {
        opacity = scrollY < 700? 1: scrollY < 1000? (((scrollY-1000)*-1)/1000):0
    }
    if ( window.screen.availWidth >= 640 && window.screen.availWidth < 1024) {
        opacity = scrollY < 1300? 1: scrollY < 1600? (((scrollY-1600)*-1)/1600):0
    }

    if ( window.screen.availWidth >= 1024) {
        opacity = scrollY < 2400? 1: scrollY < 2700? (((scrollY-2700)*-1)/2700):0
    }
  }

  return (
    <div onWheel={(event)=> null} className=" relative" style={{overflow:'hidden'}}>
        <Prompt 
            when={isBlockRoute}
            message={(location, action)=>{
                if (location.pathname === "/office" && isBlockRoute) {
                    setIsBlockRoute(false)
                    return false
                }
            }}
        />
        <div></div>
        <div className='scroll-widget-show'>
            <ScrollAnimation onClick={(event)=> null} />
        </div>
        <Chatbox onClick={()=>history.push('/contact')} />
        <div >
            <img className={'flying-kit'} src={ theme === "DARK"? personFlyingDark:personFlying} style={{opacity: opacity }}  />
            <div id={"ourWorks"} className="flex relative 2xl:justify-end">
                <div>
                    <div className='text-3xl md:text-4xl 2xl:text-5xl font-bold'>
                        <div className='hero-text mt-20 md:mt-44 lg:mt-72 2xl:mt-96 sm:pl-20 xl:pl-32'>
                            <span>
                                <span className='text-primaryOrange' >Embreo </span> 
                                works with you to incubate your product ideas 
                                <img src={homePage.idea} alt="idea" style={{display:'inline-block', width:'30px'}} />
                            </span>
                            <br/>
                            <br/>
                            <div className='text-base font-normal' >
                                With a background in conceptualizing and launching digital products, we want to help organizations digitize their business models to grow their revenues.
                            </div>
                            <Link to={"/works"} >
                                <CButton  type={'primary'} className="mt-4 md:px-10" style={{width:'fit-content'}}>
                                    <div className='flex items-center'>
                                        See Our Work
                                        <HiOutlineArrowNarrowRight className='ml-2 text-2xl' />
                                    </div>
                                </CButton>
                            </Link>
                        </div>
                        {/* <img class="black-cloud" src={homePage.cloud} alt="Cloud"></img> */}
                    </div>
                </div>
                <img class="building mt-64 sm:mt-20 md:mt-0" src={homePage.buildingTop} alt="Building Top" />
            </div>
            <div id={"partnershipModels"} className="flex relative 2xl:justify-end">
                <div>
                    <div className='text-3xl md:text-4xl 2xl:text-5xl font-bold'>
                        <div className='hero-text mt-4 md:mt-56 lg:mt-72 sm:pl-20 xl:pl-32'>
                            <div className={`${ theme === "DARK"? 'bg-darkModeTheme':'bg-white' } md:bg-transparent hero-title`} style={{ borderRadius:'50px'}}>
                                Partnership Models at 
                                <span className='text-primaryOrange' > Embreo </span>
                                <img src={homePage.offers} alt="idea" style={{display:'inline-block', width:"40px"}} />
                            </div>
                            <div className={`text-base font-normal ${ theme === "DARK"? 'bg-darkModeTheme':'bg-white' } md:bg-transparent pt-10`} style={{marginTop:'-5px', borderRadius:'50px'}}>
                                Whether you engage us as a venture partner, client or join our ecosystem
                            </div>
                            <Link to={"/partnership"} >
                                <CButton type={'primary'} className="mt-4 md:px-10" style={{width:'fit-content'}}>
                                    <div className='flex items-center'>
                                        See Models
                                        <HiOutlineArrowNarrowRight className='ml-2 text-2xl' />
                                    </div>
                                </CButton>
                            </Link>
                        </div>
                    </div>
                </div>
                <img class="first-middle-building building" src={homePage.buildingMiddleFirst} alt="Building Middle" />
            </div>
            <div id={"embreoOffers"} className="flex relative 2xl:justify-end">
                <div >
                    <div className='text-3xl md:text-4xl 2xl:text-5xl font-bold'>
                        <div className='hero-text mt-52 md:mt-56 lg:mt-72 sm:pl-20 xl:pl-32'>
                            <div className={`${ theme === "DARK"? 'bg-darkModeTheme':'bg-white' } md:bg-transparent hero-title`} style={{ borderRadius:'50px'}}>
                                What 
                                <span className='text-primaryOrange' > Embreo </span> 
                                Offers To You
                            </div>
                            <div className={`text-base font-normal ${ theme === "DARK"? 'bg-darkModeTheme':'bg-white' } md:bg-transparent pt-10`} style={{marginTop:'-5px', borderRadius:'50px'}}>
                                We drive your business growth through bespoke digital solutions, whether you have an existing idea or simply need help to review your business model.
                            </div>
                            <Link to={"/offers"} >
                                <CButton type={'primary'} className="mt-4 md:px-10" style={{width:'fit-content'}}>
                                    <div className='flex items-center'>
                                        See Offers
                                        <HiOutlineArrowNarrowRight className='ml-2 text-2xl' />
                                    </div>
                                </CButton>
                            </Link>
                        </div>
                    </div>
                </div>
                <img class="first-middle-building building" src={homePage.buildingMiddleSecond} alt="Building Middle" />
            </div>
            <div id={"workWithEmbreo"} className="flex relative 2xl:justify-end md:hidden">
                <img class="first-middle-building building" src={homePage.buildingMiddleSecond} alt="Building Middle" />
            </div>
            <div id={"workWithEmbreo"} className="flex relative 2xl:justify-end mb-20 md:mb-0">
                <div>
                    <div className='text-3xl md:text-4xl 2xl:text-5xl font-bold'>
                        <div className='hero-text text-work-with-us md:mt-56 lg:mt-96 lg:pt-32 sm:pl-20 xl:pl-40'>
                            <div className={`${ theme === "DARK"? 'bg-darkModeTheme':'bg-white' } md:bg-transparent hero-title`} style={{ borderRadius:'50px'}}>
                                Have an idea, you want to discuss?
                            </div>
                            <div className={`text-base font-normal ${ theme === "DARK"? 'bg-darkModeTheme':'bg-white' } pt-2 md:bg-transparent`} style={{marginTop:'-5px', borderRadius:'50px', width:"65%",}}>
                                Let's start your journey with us!
                            </div>
                            <Link to="/office">
                                <CButton onClick={()=> handleWannaWorkWithEmbreo()} type={'primary'} className="mt-4 md:px-10" style={{width:'fit-content'}}>
                                    <div className='flex items-center'>
                                        Let's Go!
                                        <HiOutlineArrowNarrowRight className='ml-2 text-2xl' />
                                    </div>
                                </CButton>
                            </Link>
                        </div>
                        <div  className={`${onRunning? "running-animation":""} person-running`}>
                            <div>
                                <img src={homePage.personRunning} />
                            </div>
                        </div> 
                    </div>
                </div>
                <img class="first-middle-building building" src={homePage.buildingBottom} alt="Building Bottom" />
            </div>
            <Footer style={{marginTop:'-50px', paddingTop:"10px", paddingBottom:"10px"}}/>
            {/* <div id={"ourWorks"} className={'flex justify-between w-full px-20 relative'} style={{height:'100vh', zIndex:5, overflow:'hidden'}}>
                <div className='w-1/2 flex items-center'>
                    <div>
                        <div className='text-3xl md:text-4xl 2xl:text-7xl font-bold'>
                            <span><span className='text-primaryOrange' >Embreo</span> works with you to incubate your product ideas <img src={homePage.idea} alt="idea" style={{display:'inline-block', width:'30px'}} /></span>
                        </div>
                        <br />
                        <div className='text-base'>
                            With a background in conceptualizing and launching digital products, we want to help organizations digitize their business models to grow their revenues.
                        </div>
                        <br/>
                        <br/>
                        <CButton onClick={()=> history.push('/works')} type={'primary'} className="px-10" style={{width:'fit-content'}}>
                            <div className='flex items-center'>
                                See Our Work
                                <HiOutlineArrowNarrowRight className='ml-2 text-2xl' />
                            </div>
                            
                        </CButton>
                    </div>
                </div>
                <div className='flex items-end h-full mt-52'>
                    <img src={homePage.buildingTop} alt="Top Embreo Building" />
                </div>
                
            </div> */}
            {/* <div id={'partnershipModels'} className={ 'relative flex justify-between w-full px-20'} style={{height:'100vh', overflow: 'hidden', zIndex:5}}>
                <div className='w-1/2 flex items-center'>
                    <div>
                        <div className='text-3xl md:text-4xl 2xl:text-7xl font-bold'>
                            <span>
                                Partnership Models at <span className='text-primaryOrange' >Embreo</span><img src={homePage.offers} alt="idea" style={{display:'inline-block', width:"40px"}} />
                            </span>
                            
                        </div>
                        <br />
                        <div className='text-base'>
                            Whether as a business partner, client or referral partner, we believe the modern age requires a flexible approach to every business relationship. As such, we have multiple modes of work that caters to any time of business relationship.
                        </div>
                        <br/>
                        <br/>
                        <CButton onClick={()=> history.push('/partnership')} type={'primary'} className="px-10" style={{width:'fit-content'}}>
                            <div className='flex items-center'>
                                See Models
                                <HiOutlineArrowNarrowRight className='ml-2 text-2xl' />
                            </div>
                            
                        </CButton>
                    </div>
                </div>
                <div className='flex items-end h-full'>
                    <img src={homePage.buildingMiddle} alt="Middle Embreo Building"  />
                </div>
                
            </div> */}
            {/* <div id={'embreoOffers'} className={'relative flex justify-between w-full px-20'} style={{height:'100vh', zIndex:5, overflow:'hidden'}}>
                <div className='w-1/2 flex items-center'>
                    <div>
                        <div className='text-3xl md:text-4xl 2xl:text-7xl font-bold'>
                            What <span className='text-primaryOrange' >Embreo</span> Offers To You
                        </div>
                        <br />
                        <div className='text-base'>
                            We drive your business growth through bespoke digital solutions, whether you have an existing idea or simply need help to review your business model.
                        </div>
                        <br/>
                        <br/>
                        <CButton onClick={()=> history.push('/offers')} type={'primary'} className="px-10" style={{width:'fit-content'}}>
                            <div className='flex items-center'>
                                See Offers
                                <HiOutlineArrowNarrowRight className='ml-2 text-2xl' />
                            </div>
                            
                        </CButton>
                    </div>
                </div>
                <div className='flex items-end h-full'>
                    <img src={homePage.buildingMiddle} alt="Middle Embreo Building"  />
                </div>
                
            </div> */}
        </div>
        {/* <div id={'workWithEmbreo'} className={'relative flex justify-between w-full px-20'} style={{height:'100vh', zIndex:5, overflow:'hidden'}}>
                <div className='w-1/2 flex items-center'>
                    <div>
                        <div className='text-3xl md:text-4xl 2xl:text-7xl font-bold'>
                            So, Wanna work with <span className='text-primaryOrange' >Embreo</span>?
                        </div>
                        <br />
                        <div className='text-base'>
                            Let’s start your journey with us!
                        </div>
                        <br/>
                        <br/>
                        <CButton onClick={()=> handleWannaWorkWithEmbreo()} type={'primary'} className="px-10" style={{width:'fit-content'}}>
                            <div className='flex items-center'>
                                Let's Go!
                                <HiOutlineArrowNarrowRight className='ml-2 text-2xl' />
                            </div>
                            
                        </CButton>
                    </div>
                </div>
                <div className='flex items-end h-full'>
                    <img src={homePage.buildingBottom} alt="Bottom Embreo Building" />
                </div>
                
            </div> */}
        {/* <div className='mb-10'></div> */}
    </div>
  )
}
