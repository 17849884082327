import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import theme from '../reducers/theme'
// import userPost from '../reducers/index'

const appReducer = combineReducers({
  theme: theme,
  // userPost: userPost
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  storeEnhancers(applyMiddleware(thunk)),
);

export default store;
