import * as types from '../actions/constants/theme' ;

const darkModeComponent = {
  welcomePage: {
      kitWelcome : require('../../assets/images/ilustration/welcomePage/Kit Welcome - Dark.svg'),
      messyPaper : require('../../assets/images/ilustration/welcomePage/messy-paper-dark.png'),
      messyLaptop : require('../../assets/images/ilustration/welcomePage/messy-laptop-dark.png'),
      kitMessage : require('../../assets/images/ilustration/welcomePage/KitMessages-dark.png'),
      rect : require('../../assets/images/ilustration/welcomePage/Rectangle-46-dark.png'),
  },
  homePage:{
    buildingTop: require('../../assets/images/ilustration/HomePage/Top_Building_Dark.png'),
    buildingMiddleFirst: require('../../assets/images/ilustration/HomePage/Middle_First_Building_Dark.png'),
    buildingMiddleSecond: require('../../assets/images/ilustration/HomePage/Middle_Second_Building_Dark.png'),
    buildingBottom: require('../../assets/images/ilustration/HomePage/Bottom_Building_Dark.png'),
    cloud: require('../../assets/images/ilustration/HomePage/Wanna_Work_Clouds_Light.png'),
    idea: require('../../assets/images/ilustration/HomePage/idea.png'),
    offers: require('../../assets/images/ilustration/HomePage/offers-dark.png'),
    personRunning: require('../../assets/images/ilustration/HomePage/personRunning-dark.png'),
  },
  partnershipPage:{
    partnership: require('../../assets/images/ilustration/Partnership/partnership-Light.png'),
    business: require('../../assets/images/ilustration/Partnership/business-dark.svg'),
    client: require('../../assets/images/ilustration/Partnership/client-dark.png'),
    referal: require('../../assets/images/ilustration/Partnership/referal-dark.png'),
    dot: require('../../assets/images/ilustration/Partnership/dot-dark.png'),
    brushLine: require('../../assets/images/ilustration/Partnership/yellow-brush.png')
  },
  offersPage:{
    website: require('../../assets/images/ilustration/Offers/webisite-dark.png'),
    ideation: require('../../assets/images/ilustration/Offers/ideation-dark.png'),
    mobile: require('../../assets/images/ilustration/Offers/mobile-dark.png'),
    branding: require('../../assets/images/ilustration/Offers/branding-dark.png'),
    consulting: require('../../assets/images/ilustration/Offers/consulting-dark.png'),
    offers: require('../../assets/images/ilustration/Offers/Offer-dark.svg'),
  },
  work: {
    excited: require('../../assets/images/ilustration/project/excited-Light.png'),
  }
}

const lightModeComponent = {
    welcomePage: {
      kitWelcome : require('../../assets/images/ilustration/welcomePage/Kit Welcome - Light.svg'),
      messyPaper : require('../../assets/images/ilustration/welcomePage/messy-paper-light.png'),
      messyLaptop : require('../../assets/images/ilustration/welcomePage/messy-laptop-light.png'),
      kitMessage : require('../../assets/images/ilustration/welcomePage/KitMessages.png'),
      rect : require('../../assets/images/ilustration/welcomePage/Rectangle-46.png'),
    },
    homePage:{
      buildingTop: require('../../assets/images/ilustration/HomePage/Top_Building_Light.png'),
      buildingMiddleFirst: require('../../assets/images/ilustration/HomePage/Middle_First_Building_Light.png'),
      buildingMiddleSecond: require('../../assets/images/ilustration/HomePage/Middle_Second_Building_Light.png'),
      buildingBottom: require('../../assets/images/ilustration/HomePage/Bottom_Building_Light.png'),
      cloud: require('../../assets/images/ilustration/HomePage/Wanna_Work_Clouds_Light.png'),
      idea: require('../../assets/images/ilustration/HomePage/idea.png'),
      offers: require('../../assets/images/ilustration/HomePage/offers-Light.png'),
      personRunning: require('../../assets/images/ilustration/HomePage/personRunning.png'),
    },
    partnershipPage:{
      partnership: require('../../assets/images/ilustration/Partnership/partnership-Light.png'),
      business: require('../../assets/images/ilustration/Partnership/business-Light.svg'),
      client: require('../../assets/images/ilustration/Partnership/client-Light.svg'),
      referal: require('../../assets/images/ilustration/Partnership/referral-Light.svg'),
      dot: require('../../assets/images/ilustration/Partnership/dot-Light.png'),
      brushLine: require('../../assets/images/ilustration/Partnership/yellow-brush.png')
    },
    offersPage:{
      website: require('../../assets/images/ilustration/Offers/webisite-Light.png'),
      mobile: require('../../assets/images/ilustration/Offers/mobile-Light.png'),
      ideation: require('../../assets/images/ilustration/Offers/ideation-light.png'),
      branding: require('../../assets/images/ilustration/Offers/branding-Light.png'),
      consulting: require('../../assets/images/ilustration/Offers/consulting-Light.png'),
      offers: require('../../assets/images/ilustration/Offers/Offer-Light.svg'),
    },
    work: {
      excited: require('../../assets/images/ilustration/project/excited-Light.png'),
    }
}

const initialState = {
    theme: 'LIGHT',
    component: lightModeComponent
};

export default (state = initialState, action) => {
  switch (action.type) {
  case types.TOGGLE_DARK_MODE:
    return {
      ...state,
      theme: action.theme, 
      component: action.theme === "DARK"? darkModeComponent: lightModeComponent,
    };

  default:
    return state;
  }
};
