import React from 'react'
import { useSelector } from 'react-redux'
import './style.css'

export default function CButton({children, type, className, style, onClick}) {
    const {theme} = useSelector(state=> state.theme)

    let classColor 
    if (type === "primary") {
        classColor = "bg-primaryOrange text-white"
    }
    if (type === "secondary") {
        classColor = "bg-white text-primaryOrange border-primaryOrange"
    }
    if (type === "secondary") {
        classColor = "bg-white text-primaryOrange border-primaryOrange"
    }
    if (type === "tertiary") {
        classColor = theme === "DARK"? "bg-transparent border-button-grey":"bg-lightGrey"
    }
    
    const defClass = 'rounded-lg md:rounded-xl font-bold py-1 md:py-3 px-3 text-base' + " "+ classColor + " " + className
    return (
        <div className={defClass} style={{cursor:'pointer', ...style}} onClick={onClick}>
            {children}
        </div>
    )
}
