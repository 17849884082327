import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'

//Components
import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation'
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop'
import Chatbox from '../../components/Chatbox/Chatbox'


//Images
import business1Image from '../../../assets/images/ilustration/Partnership/business-image-1.png'
import business2Image from '../../../assets/images/ilustration/Partnership/business-image-2.png'
import business3Image from '../../../assets/images/ilustration/Partnership/business-image-3.png'

import client1Image from '../../../assets/images/ilustration/Partnership/client-image-1.png'
import client2Image from '../../../assets/images/ilustration/Partnership/client-image-2.png'
import client3Image from '../../../assets/images/ilustration/Partnership/client-image-3.png'

import referal1Image from '../../../assets/images/ilustration/Partnership/referal-image-1.png'
import referal2Image from '../../../assets/images/ilustration/Partnership/referal-image-2.png'
import referal3Image from '../../../assets/images/ilustration/Partnership/referal-image-3.png'

export default function Partnership() {

    const history = useHistory()

    const {partnershipPage} = useSelector(state=> state.theme.component)
    const {theme} = useSelector(state=> state.theme)

    const [sectionList, setSectionList] = useState([
        {
            sectionId:"brief",
            id:1,
            show: true, 
            className:'fadeIn'
        },
        {
            sectionId:"detail",
            id:2,
            show: false,
            className:'fadeInBottomScroll'
        },
    ])

    const triggerScroll = (event) =>{
        if (sectionList.filter(el=> el.id === 1)[0].show && event.deltaY < 0) {
            return null
        }

        if (sectionList.filter(el=> el.id === 2)[0].show) {
            return null
        }

        setSectionList([
            {
                sectionId:"brief",
                id:1,
                show: true, 
                className:'fadeOutBottomScroll'
            },
            {
                sectionId:"detail",
                id:2,
                show: true,
                className:'fadeInBottomScroll'
            },
        ])

        event.persist();

        setTimeout(()=> {
            setSectionList([
                {
                    sectionId:"brief",
                    id:1,
                    show: false, 
                    className:'fadeIn'
                },
                {
                    sectionId:"detail",
                    id:2,
                    show: true,
                    className:'fadeInBottomScroll'
                },
            ])
        }, 1000)
    }

    const handleToTop = () =>{

        const defaultSectionList = [
            {
                sectionId:"brief",
                id:1,
                show: true, 
                className:'fadeIn'
            },
            {
                sectionId:"detail",
                id:2,
                show: false,
                className:'fadeInBottomScroll'
            },
        ]

        setSectionList(defaultSectionList)
    }

  return (
    <div className='px-4 md:px-20 min-h-screen' onWheel={(event)=> triggerScroll(event)} >
        {   sectionList.filter(el=> el.id === 1)[0].show && <ScrollAnimation onClick={(event)=> triggerScroll(event)} />}
        <Chatbox onClick={()=>history.push('/contact')}/>
            <div className={' relative pt-40 lg:pt-0 h-screen flex items-center'}>
                <div>
                    <div onClick={()=> history.goBack()} className={`mt-10 flex ${theme === "LIGHT"?"text-primaryOrange":"text-secondaryYellow"}  items-center font-bold`} style={{cursor:'pointer'}}>
                        <HiOutlineArrowNarrowLeft className='text-base' />
                        <span className='text-base ml-2'>Back</span>
                    </div>
                    <div className='mt-10 lg:flex lg:flex-row-reverse lg justify-between'>
                        <div className='flex justify-center'>
                            <div className=''>
                                <img src={partnershipPage.partnership} />
                            </div>
                        </div>
                        <div className='lg:w-1/2 mt-10 lg:mt-0'>
                            <div className='text-3xl md:text-4xl 2xl:text-7xl font-bold'>
                                What is it like to work with <span className='text-primaryOrange' >Embreo</span>?
                            </div>
                            <br />
                            <div className='text-base'>
                                Embreo provides three types of partnership models depending on your needs and business stage.
                            </div>
                            <br/>
                            <br/>
                            <div className={`text-base font-bold ${theme === "DARK"? "text-secondaryYellow": "text-accentGreen"}`}>
                                We provide you with various partnership models
                            </div>
                            <br/>
                            <div className='flex flex-wrap'>
                                <div className='flex mt-4 md:mt-0 lg:mt-4  items-center px-4'>
                                    <div className='w-8'>
                                        <img src={partnershipPage.business} />
                                    </div>
                                    <span className='text-base ml-4' >Venture Building</span>
                                </div>
                                <div className='flex mt-4 md:mt-0 lg:mt-4  items-center px-4 md:border-l-2 lg:border-0 xl:border-l-2 '>
                                    <div className='w-8'>
                                        <img src={partnershipPage.client} />
                                    </div>
                                    <span className='text-base ml-4' >Fee For Service</span>
                                </div>
                                <div className='flex mt-4 md:mt-0 lg:mt-4 items-center px-4 md:border-l-2 lg:border-0 xl:border-l-2'>
                                    <div className='w-8'>
                                        <img src={partnershipPage.referal} />
                                    </div>
                                    <span className='text-base ml-4' >Ecosystem Partner</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={" relative mt-20"}>
                {/* Section 1 */}
                <div className='pt-20 flex flex-col-reverse lg:flex-row justify-between'>
                    <div className='lg:w-1/2'>
                        <div className='text-xl text-primaryOrange font-bold'>
                            Venture Building
                        </div>
                        <br />
                        <div className='text-3xl md:text-4xl 2xl:text-7xl font-bold'>
                            Partnering you for the long haul
                        </div>
                        <br />
                        <div className='text-base'>
                            Our venture building model puts us in the same shoes as you.
                            <br/>
                            <br/>
                            We work with you on a cost based model and hinge our profitability on the product's success through revenue sharing or equity models.
                            <br/>
                            <br/>
                            Partners of this model are expected to fulfil operational requirements of the venture and should come with subject domain expertise.
                        </div>
                        <br/>
                        <div className='flex justify-center'>
                            <img src={partnershipPage.brushLine} />
                        </div>
                    </div>
                    <div className='flex justify-center mt-10 lg:mt-0'>
                        <div className=' flex'>
                            <div>
                                <div className='relative w-48'>
                                    <div className='w-20 pt-4' >
                                        <img src={partnershipPage.dot} />
                                    </div>
                                    <div className='absolute' style={{top:'0px', left:'40px'}}>
                                        <img src={business1Image} />
                                    </div>
                                </div>
                                <div className='relative flex justify-end items-end w-48 h-56 mt-14'>
                                    <div className='w-20 pr-4' >
                                        <img src={partnershipPage.dot} />
                                    </div>
                                    <div className='absolute ml-8 md:ml-0 w-40 md:w-48' style={{top:'0px', left:'0px'}}>
                                        <img src={business2Image} />
                                    </div>
                                </div>
                            </div>
                            <div className='ml-4'>
                                <div className='relative flex justify-end w-32 h-1/3'>
                                    <div className='w-20' >
                                        <img src={partnershipPage.dot} />
                                    </div>
                                    <div className='absolute w-32' style={{top:'40px', left:'0px'}}>
                                        <img src={business3Image} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 2 */}
                <div className='mt-20 lg:flex justify-between'>
                    <div className='flex justify-end'>
                        <div className=' flex'>
                            <div className='mr-4'>
                                <div className='relative flex w-32 h-1/3'>
                                    <div className='w-20' >
                                        <img src={partnershipPage.dot} />
                                    </div>
                                    <div className='absolute w-32' style={{top:'40px', left:'0px'}}>
                                        <img src={client3Image} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='relative flex justify-end w-48'>
                                    <div className='w-20 pt-4' >
                                        <img src={partnershipPage.dot} />
                                    </div>
                                    <div className='absolute' style={{top:'0px', right:'40px'}}>
                                        <img src={client1Image} />
                                    </div>
                                </div>
                                <div className='relative flex items-end w-48 h-56 mt-14'>
                                    <div className='w-20 pl-4' >
                                        <img src={partnershipPage.dot} />
                                    </div>
                                    <div className='absolute w-48' style={{top:'0px', left:'0px'}}>
                                        <img src={client2Image} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full lg:w-1/2'>
                        <div className='text-xl text-primaryOrange font-bold'>
                            Fee For Service
                        </div>
                        <br />
                        <div className='text-3xl md:text-4xl 2xl:text-7xl font-bold'>
                            Outsourcing your needs
                        </div>
                        <br />
                        <div className='text-base'>
                            Our fee for service model sees us working with you as a client-vendor relationship. 
                            <br/>
                            <br/>
                            Embreo handles all aspect of your development journey from conceptualization to development, reducing the amount of work and resources required from your team to develop a product.    
                        </div>
                        <br/>
                        <div className='flex justify-center'>
                            <img src={partnershipPage.brushLine} />
                        </div>
                    </div>
                    
                </div>

                {/* Section 3 */}
                <div className='mt-20 mb-20 flex flex-col-reverse lg:flex-row justify-between'>
                    <div className='lg:w-1/2'>
                        <div className='text-xl text-primaryOrange font-bold'>
                            Ecosystem
                        </div>
                        <br />
                        <div className='text-3xl md:text-4xl 2xl:text-7xl font-bold'>
                            Holistic ecosystem
                        </div>
                        <br />
                        <div className='text-base'>
                            To support our ventures and clients in their product/business development journey, Embreo curates a network of preferred partners that supports activities beyond digitization regionally.
                            <br/>
                            <br/>
                            This can range from operational and administrative services, such as branding, marketing to even accounting or intellectual property advisor. 
                            <br/>
                            <br/>
                            We also have partners in selected countries regionally to support with product expansion overseas.
                        </div>
                        <br/>
                        <div className='flex justify-center'>
                            <img src={partnershipPage.brushLine} />
                        </div>
                    </div>
                    <div className='flex justify-center mt-10 lg:mt-0'>
                        <div className=' flex'>
                            <div>
                                <div className='relative w-48'>
                                    <div className='w-20 pt-4' >
                                        <img src={partnershipPage.dot} />
                                    </div>
                                    <div className='absolute' style={{top:'0px', left:'40px'}}>
                                        <img src={referal1Image} />
                                    </div>
                                </div>
                                <div className='relative flex justify-end items-end w-48 h-56 mt-14'>
                                    <div className='w-20 pr-4' >
                                        <img src={partnershipPage.dot} />
                                    </div>
                                    <div className='absolute  ml-8 md:ml-0 w-40 md:w-48' style={{top:'0px', left:'0px'}}>
                                        <img src={referal2Image} />
                                    </div>
                                </div>
                            </div>
                            <div className='ml-4'>
                                <div className='relative flex justify-end w-32 h-1/3'>
                                    <div className='w-20' >
                                        <img src={partnershipPage.dot} />
                                    </div>
                                    <div className='absolute w-32' style={{top:'40px', left:'0px'}}>
                                        <img src={referal3Image} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ScrollToTop onClick={()=> handleToTop()}/> */}
            </div>
    </div>
  )
}
