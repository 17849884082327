import React from 'react'
import { useSelector } from 'react-redux'
import './style.css'

export default function CInput({children, type = "text", className = "", textArea, id, onChange, label, optional, style, placeholder, value}) {
    const {theme} = useSelector(state=> state.theme)
    
    const defClass = `custome-input-group ${textArea? "":" flex "}`   + className
    return (
        <div className={defClass} style={{cursor:'pointer', border:textArea?'none':null, ...style}} >
            <label htmlFor={id} >{label} {optional && <span className='text-primaryOrange mr-6'>{"( Optional )"}</span>}</label>
            
            {
                textArea?
                <textarea value={value} type={"text"} id={id} onChange={onChange} rows={4} className={'mt-3'} placeholder={placeholder} style={{border: theme === "DARK"? "1px solid white": null, backgroundColor:theme === "DARK"? "transparent": null}}  />
                :
                <input value={value} type={type} id={id} onChange={onChange} placeholder={placeholder}  />
            }
        </div>
    )
}
