import React from 'react';
import { useSelector } from 'react-redux';
import './style.css'
 

//image
import logo from '../../assets/images/footer-logo.png'
import copyright from '../../assets/images/copyright-icon.png'
import { Link } from 'react-router-dom';
const Headers = ({style}) => {

  const {theme} = useSelector(state => state.theme)

    return (
      <footer className={`flex md:justify-between px-2 md:px-0 py-4 inter-font ${theme === "DARK"? "darkGrey":"bg-primaryOrange"} text-white`} style={{position:"relative", zIndex:"5",  ...style,}}>
        <div className='flex text-sm md:text-base items-center justify-center md:w-1/5 px-3 md:px-10' style={{borderRight: "1px solid white"}}>
          <div>
            <img className='mr-2' src={logo} />
          </div>
          <span>Embreo</span>
        </div>
        <div className='flex text-sm md:text-base items-center justify-center md:w-2/5 lg:w-1/5 px-2 md:px-10' style={{borderRight: "1px solid white"}}>
          <span>+65 9125 6736</span>
        </div>
        <div className='flex text-sm md:text-base items-center justify-center md:w-2/5 lg:w-1/5  px-2 md:px-10' style={{ wordBreak:"break-all", borderRight: "1px solid white"}}>
          <span>tech@embreo.global</span>
        </div>
        <div className=' footer-hidden items-center justify-center w-1/5 px-10' style={{borderRight: "1px solid white"}}>
          <span>33 Ubi Avenue 3 #08-13 Vertex, Singapore 408868</span>
        </div>
        <div className='footer-hidden items-center justify-center w-1/5 px-10 footer-border-right-solid'>
          <img src={copyright} />
          <span className='ml-4'>Embreo. ALL RIGHTS RESERVED</span>
        </div>
        <div className='privacy_policy_link flex text-sm md:text-base items-center justify-center md:w-1/5  px-2 md:px-10'>
          <Link to={'/privacy-policy'} >Privacy Policy</Link>
        </div>
      </footer>
    );
}

export default Headers;
