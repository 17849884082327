import React from 'react'
import { Link } from 'react-router-dom'

function LinkText ({text, url}){
    return (
        <>
            <a 
                href={url} 
                target={"_blank"} 
            > 
                <span className='text-blue-600'>
                    {text}
                </span>
            </a>
        </>
    )
}

export default function index() {
  return (
    <div className='px-4 md:px-20 min-h-screen pt-28' >
        <h1 className='text-primaryOrange text-3xl md:text-4xl 2xl:text-5xl font-bold'>Privacy Policy</h1>
        <br />
        <p>Your privacy is important to us.</p>
        <br />
        <p className='font-bold text-xl' >Data Protection Policy (“Policy”)</p>
        <div>
            <p className='font-bold text-lg'  >1.  Introduction</p>
            <div className='pl-4 flex'>
                <span>1.1.</span>
                <span className='ml-2' >
                    At Embreo Ltd (“Embreo”, “us”, “we”, “our”), we understand the importance of your Personal Data. We are committed to protecting the confidentiality and privacy of your Personal Data.
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>1.2.</span>
                <span className='ml-2' >
                    This Policy helps you understand how we deal with (such as collecting, using, disclosing and/or processing) your Personal Data on our website (at <LinkText url={"https://embreo.global"} text={"www.embreo.global"} /> ) and advertisements.
                    <br />
                    <br />
                    Please feel free to contact our Data Protection Officer at <LinkText url={"mailto: tech@embreo.global"} text={"tech@embreo.global"} />  if you have any queries.
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>1.3.</span>
                <span className='ml-2' >
                    Definitions and key terms
                    <div>
                        To help explain things as clearly as possible in this Policy, the words below shall bear the following meanings:
                    </div>
                    <br />
                    <div>
                        <strong>Device</strong>: any internet connected device such as a phone, tablet, computer, or any other device that can be used to visit the platform and use the services.
                    </div>
                    <br />
                    <div>
                        <strong>Personal Data</strong>: refers to data, whether true or not, about an individual who can be identified from that data, or from that data and other information to which an organisation has or is likely to have access as defined under the Personal Data Protection Act (No. 26 of 2012) (“PDPA”). This includes personal data provided by you and collected by Embreo Pte Ltd. Examples include but are not limited to names, identification numbers, email addresses, home addresses, telephone numbers, medical records, lifestyle preferences, personal interests, work-related information, photographs and video images.
                    </div>
                    <br />
                    <div>
                        <strong>Third-party service providers</strong>  refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide content on the platform or whose products or services we think may interest you.
                    </div>
                    <br />
                    <div>
                        <strong>You</strong>: a company who registers for our mailing list, online advertisement or a company who is intending to purchase services from Embreo Pte ltd.
                    </div>
                </span>
            </div>
        </div>
        <br />
        <div>
            <p className='font-bold text-lg'  >2.  Consent and Changes to this Policy</p>
            <div className='pl-4 flex'>
                <span>2.1.</span>
                <span className='ml-2' >
                    By accessing the website and providing us your information, you hereby agree to this Policy, and expressly consent to us dealing with your Personal Data (such as collecting, using, disclosing and/or processing) in accordance with and as set out in this Policy.
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>2.2.</span>
                <span className='ml-2' >
                    We reserve the right to modify or change this Policy at any time. Any updated version of this Policy shall take effect immediately and shall supersede all earlier versions but shall continue to apply to all data provided to us by your use of the website previously. Subject to your rights under the PDPA, you hereby agree to and consent to the prevailing terms of any updated version of this Policy.
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>2.3.</span>
                <span className='ml-2' >
                    We encourage you to periodically reread this Policy to see if there have been any changes that may affect you. Any changes to this Policy shall be made available on the website and shall be published on this page.
                </span>
            </div>
            <br />
        </div>
        <br />
        <div>
            <p className='font-bold text-lg'  >3. Collecting, Using, Disclosing and Processing Personal Data</p>
            <div className='pl-4 flex'>
                <span>3.1.</span>
                <span className='ml-2' >
                    We may collect, use, disclose and process your Personal Data for various purposes as listed below:
                    <span className='pl-4 flex'>
                        <span>(a)</span>
                        <span className='ml-2'>
                            To administer any form that you submit via the website including but not limited to registration, application forms and lead forms. We shall do so in a manner to help you complete the usage of our services and to render our services more efficiently. This includes contacting you, collecting your financial information to process payment for our services or to process application of grants/subsidies, and promoting and announcing contest winners.
                        </span>
                    </span>
                    <br />
                    <span className='pl-4 flex'>
                        <span>(b)</span>
                        <span className='ml-2'>
                            To provide our services to you when you request and/or use our services and/or enter into an agreement with us. This includes our lead forms and contacting you (for example, when you request to be contacted either in response to your queries or to be placed on a mailing list, when we need to contact you and for keeping notes to fulfill our services to you). We also use cloud services to process and store Personal Data; we only use cloud service providers who comply with the PDPA.
                        </span>
                    </span>
                    <br />
                    <span className='pl-4 flex'>
                        <span>(c)</span>
                        <span className='ml-2'>
                            To improve our services to you. This includes collecting and storing history of our interactions with you (for example, the types of services and promotions offered to and/or used by you), generating statistics and research results (such as tracking user behaviour and hotspots on the Embreo website), and your location history which shall enable us to enhance your customer experience.. You may also be prompted to complete market research surveys.
                        </span>
                    </span>
                    <br />
                    <span className='pl-4 flex'>
                        <span>(d)</span>
                        <span className='ml-2'>
                            To comply with any law, regulation or governmental requirement as may be applicable to us from time to time. These include any record-keeping and statutory reporting requirements.
                        </span>
                    </span>
                    <br />
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>3.2.</span>
                <span className='ml-2' >
                    Any purpose not listed in this Policy shall be notified to you at the time of collection and your consent shall be sought no later than at the time of collection.
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>3.3.</span>
                <span className='ml-2' >
                    We may also disclose your Personal Data to our subsidiaries, affiliates, officers, employees, agents, partners, legal advisors, and other third parties including but not limited to third-party service providers who would process your Personal Data for the above purposes, and they shall be required to treat your Personal Data in accordance with this Policy and the PDPA. The aforesaid parties help us to make our business more efficient and effective. Where we disclose Personal Data to third parties, we shall take reasonable steps to protect the security of your Personal Data. In the event that any of the aforesaid parties are located outside Singapore, we shall comply with the requirements under the PDPA in doing so and we shall ensure the same standard of Personal Data protection in the relevant jurisdiction as required under the PDPA.
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>3.4.</span>
                <span className='ml-2' >
                    We may disclose your Personal Data without your consent strictly only as provided under and in accordance with the PDPA.
                </span>
            </div>
            <br />
        </div>
        <div>
            <p className='font-bold text-lg'  >4. Contacting Us</p>
            <div className='pl-4 flex'>
                <span>4.1.</span>
                <span className='ml-2' >
                    All queries, requests, and feedback on this Policy and/or regarding Personal Data can be directed to our Personal Data Protection Officer at: <LinkText url={"mailto: tech@embreo.global"} text={"tech@embreo.global"} />. This includes any request from you to withdraw your consent to the use of any of your Personal Data, correcting your Personal Data or accessing your Personal Data.
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>4.2.</span>
                <span className='ml-2' >
                    In the event that you seek to withdraw your consent, correct or access your Personal Data, please note that we shall carry out a verification process to ascertain your identity. We may also require more information for clarification before we can process your request. Upon you contacting us, we shall use our best efforts to get back to you within a reasonable period. In the event that we are unable to perform your request substantively, we shall inform you of the rejection within a reasonable period.
                </span>
            </div>
            <br />
        </div>
        <div>
            <p className='font-bold text-lg'  >5. Managing your Personal Data</p>
            <div className='pl-4 flex'>
                <span>5.1.</span>
                <span className='ml-2' >
                    We take reasonable steps to ensure the accuracy and completeness of your Personal Data. Should you provide us inaccurate and/or incomplete Personal Data or fail to update us of any change in your Personal Data, we are not responsible for relying on any of the aforesaid Personal Data.
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>5.2.</span>
                <span className='ml-2' >
                    We also retain records of your Personal Data only for a reasonable period of time for the above-stated purposes and in accordance with the PDPA. In the event that your Personal Data is no longer required, we shall anonymise and/or destroy the Personal Data in our control as soon as reasonably possible.
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>5.3.</span>
                <span className='ml-2' >
                    We also use reasonable efforts to ensure the security of your Personal Data and have reasonable security processes/arrangements to protect your Personal Data. We regularly review our security practices to consider appropriate new technology and methods. However, please be aware that despite our best efforts, no security measures are perfect or impenetrable.
                </span>
            </div>
            <br />
        </div> 
        <div>
            <p className='font-bold text-lg'  >6.   Cookie Policy and Tracking Technologies</p>
            <div className='pl-4 flex'>
                <span>6.1.</span>
                <span className='ml-2' >
                    When you visit the platform, we may use “cookies” and other technologies such as pixel tags and web beacons (“Tracking Technologies”). These technologies are used to track information such as the number of users, their frequency of use, and which parts of the platform they have visited. Tracking Technologies may also identify your browser and store information on your Device about you such as your language preference.
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>6.2.</span>
                <span className='ml-2' >
                    By accessing and using the platform, you agree to our use of Tracking Technologies.
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>6.3.</span>
                <span className='ml-2' >
                    We currently use Tracking Technologies on the platform for various purposes, including but not limited to improving your user experience on the platform, remembering your preferences, targeting advertising that might be of interest to you, helping us understand how the platform is performing, measuring the overall effectiveness of our online content, advertising campaigns, and the products and services we offer through the platform, monitoring traffic to the platform, detecting your web browser’s multimedia capabilities, tracking promotional advertisements that have been displayed to you and maintaining current login and purchase information on secured portions of the platform (the “Tracking Technologies Purposes”).
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>6.4.</span>
                <span className='ml-2' >
                    Information that we collect from you through the Tracking Technologies may be passed to our third-party service providers, whether located in Singapore or elsewhere, for one or more of the Tracking Technologies Purposes, for managing and/or administering the platform, or for the purpose of data hosting/storage/backup.
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>6.5.</span>
                <span className='ml-2' >
                    Third-parties may also use Tracking Technologies when you use the platform. Third parties, such as our social media (including Facebook), advertising, analytics and business partners, may use Tracking Technologies to collect information about your online activities while you are on the platform. This information may be used to measure the usage of the platform and personalise advertising content on the platform. We do not have access to or control over Tracking Technologies or other features these third parties may use, and the information practices of these third-parties are not covered by this Policy. Please refer to the policies of those third-parties to better understand their activities and practices.
                </span>
            </div>
            <br />
            <div className='pl-4 flex'>
                <span>6.5.</span>
                <span className='ml-2' >
                    Most browsers automatically accept cookies, but you can modify your browser settings to decline          cookies. Generally, you can remove these cookies by following directions provided in your Internet browser’s “help” file. If you choose not to allow us to place a cookie on your Device, you may be restricted from some services and some of the interactive features offered on the platform may be similarly restricted or rendered inoperable.
                </span>
            </div>
            <br />
        </div>
        <div>
            <p className='font-bold text-lg'  >7. Governing Law</p>
            <div className='pl-4 flex'>
                <span>7.1.</span>
                <span className='ml-2' >
                    This Policy and your use of the platform shall be governed in all respects by the laws of the Republic of Singapore.
                </span>
            </div>
            <br />
        </div>
    </div>
  )
}
