export const START_REQUEST = 'START_REQUEST';
export const BLOGS_RECEIVED = 'BLOGS_RECEIVED';
export const USERS_POST = 'USERS_POST';
export const BLOG_DETAIL = 'BLOG_DETAIL';
export const CATEGORY = 'CATEGORY';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const JOIN_NEWSLETTER = 'JOIN_NEWSLETTER';

export default {
  START_REQUEST,
  BLOGS_RECEIVED,
  USERS_POST,
  BLOG_DETAIL,
  CATEGORY,
  SELECT_CATEGORY,
  JOIN_NEWSLETTER,
};
