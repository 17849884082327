import React, { useEffect, useState } from 'react'
import './style.css'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'
import {RiInformationFill} from 'react-icons/ri'

//Components
import CButton from '../../components/Button/CButton'

//Import Data
import { projects } from '../../../data/works'
// import worksData from './worksData'

export default function Works() {

    const history = useHistory()

    const {partnershipPage} = useSelector(state=> state.theme.component)
    const {theme} = useSelector(state=> state.theme)
    
    const [singleProject, setSingleProject] = useState(null)

    useEffect(()=>{
        if (projects && projects.length > 0) {
            setSingleProject(projects[0])
        }
    },[])

    useEffect(()=>{
        function scrollHorizontally(e) {
            e = window.event || e;
            var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
            document.getElementById('projectSlide').scrollLeft -= (delta*40); // Multiplied by 40
            e.preventDefault();
        }
        if (document.getElementById('projectSlide').addEventListener) {
            // IE9, Chrome, Safari, Opera
            document.getElementById('projectSlide').addEventListener("mousewheel", scrollHorizontally, false);
            // Firefox
            document.getElementById('projectSlide').addEventListener("DOMMouseScroll", scrollHorizontally, false);
        } else {
            // IE 6/7/8
            document.getElementById('projectSlide').attachEvent("onmousewheel", scrollHorizontally);
        }
    })
    

  return (
    <div className='works-container px-4 lg:px-20 pt-32 min-h-screen' style={{backgroundImage:`url(${singleProject? singleProject.mainBg:''})`, backgroundRepeat:'no-repeat', backgroundSize:'cover'}} >
        <div className='content text-white'>
            <div className='w-full flex justify-between lg:pr-12'>
                <div onClick={()=> history.goBack()} className={`flex items-center font-bold`} style={{cursor:'pointer'}}>
                    <HiOutlineArrowNarrowLeft className='text-base' />
                    <span className='text-base ml-2'>Back</span>
                </div>
                <div className='flex items-center'>
                    <span className='text-sm' style={{letterSpacing: "0.015em"}}>Choose project to see details </span>
                    <RiInformationFill className="ml-2 text-secondaryYellow text-xl" />
                </div>
            </div>
            <div className='mt-10 lg:mt-0 flex flex-col-reverse lg:flex-row justify-between items-start'>
                <div className='w-full lg:w-2/5 mt-10 lg:mt-20 lg:mt-0'  >
                    <div className='text-xl md:text-2xl text-secondaryYellow font-bold'>
                        Our Works
                    </div>
                    <div className='text-3xl md:text-4xl 2xl:text-9xl font-bold text-white'>
                        {singleProject && singleProject.name}
                    </div>
                    <br />
                    <div className='text-base'>
                        {singleProject && singleProject.summary.map((item, index)=>{
                            return (
                                <div>
                                    <span>{item}</span>
                                    {
                                        singleProject.summary.length > 1 && index + 1 < singleProject.summary.length  &&
                                        <div>
                                            <br/>
                                            --
                                            <br/>
                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <br/>
                    <br/>
                    <div>
                        <CButton onClick={()=> history.push(`/works/${singleProject.name}`)} type={'secondary'} className="px-16" style={{border:'2px solid #FF7360', width:'fit-content'}} >
                            Read Details
                        </CButton>
                    </div>
                    <br/>
                </div>
                <div className='flex mr-12 w-full lg:w-1/2 overflow-auto' id='projectSlide' >
                    <div className='flex items-center'  >
                        {
                            projects && projects.length > 0 && projects.map((item, index)=>{
                                return (
                                    <div onClick={()=> setSingleProject(item)} key={index} className={`${singleProject && singleProject.id === item.id? "w-52 h-72":'w-24 h-36'} bg-white rounded-lg flex justify-center items-center ${index === 0? "":"ml-8"}`}>
                                        <div  className={singleProject && singleProject.id === item.id?`w-32`:'w-14'}>
                                            {item && <img src={item.logo}/>}
                                        </div>
                                    </div>
                                )
                            }) 
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
