import React from 'react'
import {useSelector} from 'react-redux'
import {BsFillArrowUpCircleFill} from 'react-icons/bs'
import './style.css'

//Images
import starIcon from '../../../assets/star-icon.png'

export default function StarBackground ({zIndex, hideStar}){

  const {theme} = useSelector(state=> state.theme)
  const hiddenStar = hideStar? hideStar: []
  return (
    <div>
      {
        theme === "DARK"? 
        (
          <div className='absolute w-full h-screen' style={{zIndex: zIndex || null }} >
            {hiddenStar.indexOf('star1') < 0 && <img className='star-1 absolute' src={starIcon} />}
            {hiddenStar.indexOf('star2') < 0 && <img className='star-2  absolute' src={starIcon} />}
            {hiddenStar.indexOf('star3') < 0 && <img className='star-3 absolute' src={starIcon} />}
            {hiddenStar.indexOf('star4') < 0 &&<img className='star-4 absolute' src={starIcon} />}
          </div>
        ):(
          <div></div>
        )
      }
    </div>
    
  )
}
